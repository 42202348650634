import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {Publish} from '@app/model/publish.model';
import {ActivatedRoute, UrlSegment} from '@angular/router';
import {Constants} from '@app/utils/constants/constants';
import {BaseComponent} from '@app/components/base/base.component';
import {EventService} from '@app/services/event.service';

@Component({
  selector: 'app-publish-data',
  templateUrl: './publish-data.component.html'
})
export class PublishDataComponent extends BaseComponent implements OnInit {

  publishData: Array<Publish>;
  viewPublishData: Array<Publish>;
  pageSize = 5;
  currentPage = 1;
  constructor(public route: ActivatedRoute, private eventService: EventService<any>) {
    super();
  }

  ngOnInit() {
    this.route.url.subscribe((url) => {

      this.populateData(url);
      this.eventService.getAllRowsEventListener().subscribe((value: boolean) => {
        this.publishData.forEach((row: Publish) => {
          row.selected = value ? 'Y' : 'N';
          this.eventService.emitPublishDataEvent(this.publishData);
        });
      });
    });
  }

  populateData(url: UrlSegment[]) {
    if (url[0].path === 'queued') {
      this.publishData = JSON.parse(localStorage.getItem(Constants.PUBLISH_DATA))
        .filter((x: Publish) => x.status === Constants.QUEUED_STATUS);
    } else if (url[0].path === 'draft') {
      this.publishData = JSON.parse(localStorage.getItem(Constants.PUBLISH_DATA))
        .filter((x: Publish) => x.status === Constants.DRAFT_STATUS);
    } else {
      this.publishData = JSON.parse(localStorage.getItem(Constants.PUBLISH_DATA));
    }
    this.viewPublishData = this.publishData.slice(this.currentPage - 1, this.currentPage * this.pageSize);
  }

  callBack(value: boolean) {
    // @ts-ignore
    this.data.eventService.emitPublishDataEvent(this.data.publishData);
  }


  revert(row: Publish) {
    this.eventService.emitRevertEventListener(row);
  }

  queue(row: Publish) {
    this.eventService.emitQueueEventListener(row);
  }

  publish(row: Publish) {
    this.eventService.emitPublishEventListener(row);
  }

}
