import {Component, Input, OnInit} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {CommonUtils} from '@app/utils/common/common-utils';
import {MzBaseModal} from 'ngx-materialize';
import {PublishOptions} from '@app/components/publish-modal/publish-options';
import {PublishType} from '@app/utils/constants/publish-constants';
import {PublishService} from '@app/services/publish.service';
import {Constants} from '@app/utils/constants/constants';
import {EventService} from '@app/services/event.service';
import {Response} from '@app/model/response.model';

export enum PublishState {
  Confirm,
  Publishing,
  Complete
}

@Component({
  selector: 'app-publish-modal',
  templateUrl: './publish-modal.component.html'
})
export class PublishModalComponent extends MzBaseModal implements OnInit {
  @Input() publishOptions: PublishOptions;
  theme = CommonUtils.getCompany().toLocaleLowerCase();
  title: string;
  message: string;
  currentState: PublishState = PublishState.Confirm;
  PublishState = PublishState;
  publishSucceeded = false;

  constructor(private publishService: PublishService,
              private eventService: EventService<string>) {
    super();
  }

  ngOnInit() {
    this.title = this.getTitleText();
    this.message = this.getConfirmMessageText();
  }

  publish() {
    this.currentState = PublishState.Publishing;

    this.publishContent().subscribe((response) => {
      this.currentState = PublishState.Complete;
      this.publishSucceeded = response.message === Constants.SUCCESS;
      this.message = this.publishSucceeded
        ? 'Content published!'
        : 'An error occurred while publishing.  Please contact a system administrator.';
    });
  }

  complete() {
      this.eventService.emit('reload');
  }

  private onPublishError(err) {
    this.currentState = PublishState.Complete;
    this.message = 'An error occurred while publishing.  Please contact a system administrator.';

    return throwError('An error occurred while publishing content.');
  }

  private getTitleText(): string {
    let titleText = '';

    switch (this.publishOptions.publishType) {
      case PublishType.Manual:
        titleText = 'Publish Accounting Manual';
        break;
      case PublishType.Overview:
        titleText = 'Publish Overview';
        break;
      case PublishType.Page:
        titleText = 'Publish Page';
        break;
      case PublishType.Appendix:
        titleText = 'Publish Appendix';
        break;
    }

    return titleText;
  }

  private getConfirmMessageText(): string {
    let confirmText = '';

    switch (this.publishOptions.publishType) {
      case PublishType.Manual:
        confirmText = 'Publish all queued content in the entire manual?';
        break;
      case PublishType.Overview:
        confirmText = 'Publish all queued content on the overview?';
        break;
      case PublishType.Page:
        confirmText = `Publish all queued content on page ${this.publishOptions.pageNumber}? Account details will also be published for new accounts if not already published.`;
        break;
      case PublishType.Appendix:
        confirmText = `Publish all queued content on Appendix page?`;
        break;
    }

    return confirmText;
  }

  private publishContent(): Observable<Response> {
    let response: Observable<Response>;

    switch (this.publishOptions.publishType) {
      case PublishType.Manual:
        response = this.publishService.publishManual((err) => this.onPublishError(err));
        break;
      case PublishType.Overview:
        response = this.publishService.publishOverview((err) => this.onPublishError(err));
        break;
      case PublishType.Page:
        response = this.publishService.publishPage(this.publishOptions.queuedId, this.publishOptions.pageNumber,
          true, (err) => this.onPublishError(err));
        break;
      case PublishType.Appendix:
        response = this.publishService.publishAppendix(this.publishOptions.data);
        break;
    }

    return response;
  }
}
