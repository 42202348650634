import { Component, Input, OnInit } from '@angular/core';
import { Example } from '@app/model/example.model';

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html'
})
export class ExampleComponent implements OnInit {

  @Input() example: Example;

  constructor() {
  }

  ngOnInit() {
  }
}
