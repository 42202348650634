import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {AdminService} from '@app/services/admin.service';
import {PageDefinitionService} from '@app/services/page-definition.service';
import {ShowdownConverter} from 'ngx-showdown';
import {ConfigUtils} from '@app/utils/common/Config-Util';
import {EventService} from '@app/services/event.service';
import {DocumentModel} from '@app/model/document.model';
import {Constants} from '@app/utils/constants/constants';
import { CommonUtils } from '@app/utils/common/common-utils';
import { Page } from '@app/model/page.model';

@Component({
  selector: 'app-edit-overview',
  templateUrl: './edit-overview.component.html'
})
export class EditOverviewComponent extends BaseComponent implements OnInit {
  public overviewPage: Page;
  public descriptionHtml: string;
  public selectedFile: string;
  public selectedFileName: string;
  public imgData: string;
  loading = true;

  config = ConfigUtils.getEditorConfig();

  constructor(public adminService: AdminService,
              public pageDefService: PageDefinitionService,
              public showdownConverter: ShowdownConverter,
              public docService: EventService<DocumentModel>) {
    super();

  }

  ngOnInit() {
    this.initAdminEventHandlers();
    this.loadOverview(undefined);
  }

  loadOverview(expectedStatus?) {
    this.pageDefService.getAllPageDefinitionsForEdit().subscribe(result => {
      localStorage.setItem(Constants.PAGE_DEFINITIONS, JSON.stringify(result));
      this.overviewPage = CommonUtils.getPage(0);
      if (this.overviewPage.status === expectedStatus || expectedStatus === undefined) {
        this.descriptionHtml = this.showdownConverter.makeHtml(this.overviewPage.description);
        this.imgData = null;
        this.selectedFile = null;
        this.selectedFileName = null;
        if (this.overviewPage.status === Constants.PUBLISHED_STATUS) {
          this.overviewPage.publishedId = this.overviewPage.id;
          this.overviewPage.id = null;
        }
        const doc = DocumentModel.initFromOverview(this.overviewPage, 'Page Definition');
        this.docService.emit(doc);
        this.loading = false;
        this.docService.emitReload('');
      } else {
        this.delay(1000).then(() => this.loadOverview(expectedStatus));
      }
    });
  }

  fileSelected(evt) {
    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (readerEvt) => this.imageLoadComplete(readerEvt);
      reader.readAsDataURL(file);
    }

    this.selectedFileName = this.parseFilename(this.selectedFile);
  }

  removeImage() {
    this.overviewPage.overviewImage = '';
    this.imgData = null;
    this.selectedFile = null;
    this.selectedFileName = '';
  }

  descriptionChanged($event) {
    this.overviewPage.description = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  private imageLoadComplete(readerEvt) {
    // display the selected image
    this.overviewPage.overviewImage = readerEvt.target.result;

    // capture the base64-encoded image data for saving
    const dataUrlPrefix = 'data:image/png;base64,';
    this.imgData = this.overviewPage.overviewImage.substring(dataUrlPrefix.length);
  }

  private initAdminEventHandlers() {
    this.adminService.deleteClicked.subscribe(() => alert('delete!'));
    this.adminService.revertClicked.subscribe(() => this.revert());
    this.adminService.saveAsDraftClicked.subscribe(() => this.saveAsDraft());
    this.adminService.queueClicked.subscribe(() => this.queue());
  }

  saveAsDraft() {
    this.loading = true;
    this.overviewPage.overviewImage = this.imgData;
    this.pageDefService.savePageDefinitions(CommonUtils.getYear(), this.overviewPage).subscribe(() => {
      this.loadOverview(Constants.DRAFT_STATUS);
    });
  }

  revert() {
    this.loading = true;
    if (this.overviewPage.publishedId !== null) {
      this.pageDefService.revertPageDefinitions(this.overviewPage.id).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.loadOverview(Constants.PUBLISHED_STATUS);
        }
      });
    }
  }

  queue() {
    this.loading = true;
    this.docService.emit(undefined);
    if (this.overviewPage.publishedId !== null) {
      this.pageDefService.queuePageDefinitions(this.overviewPage.id).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.loadOverview(Constants.QUEUED_STATUS);
        }
      });
    }
  }

  private parseFilename(filePath: string) {
    if (!filePath) {
      return '';
    }
    const start = filePath.indexOf('\\') >= 0 ? filePath.lastIndexOf('\\') : filePath.lastIndexOf('/');
    const filename = filePath.substring(start);
    return (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) ? filename.substring(1) : filename;
  }
}
