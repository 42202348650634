import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env';
import {AuthService} from '@app/services/auth.service';
import {AuthConstants} from '@app/utils/constants/auth-constants';
import {PublishConstants} from '@app/utils/constants/publish-constants';
import {Constants} from '@app/utils/constants/constants';
import {AccessTokenResponse} from '@app/utils/common/accessTokenResponse';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let baseUrl = `${environment.awsLambdaBaseUrl}`;
    if (req.url.includes(PublishConstants.PUBLISH_PREFIX) || req.url.includes(PublishConstants.APPENDIX_DELETE)) {
      baseUrl = `${environment.awsPublishLambdaBaseUrl}`;
    } else if (req.url.includes(Constants.ACCOUNTS_URL_PREFIX)) {
      baseUrl = `${environment.awsAccountsLambdaBaseUrl}`;
    }
    if (req.url.startsWith('https://') || req.url.startsWith('http://')) {
      return next.handle(req);
    }
    const token: AccessTokenResponse = JSON.parse(localStorage.getItem(AuthConstants.ACCESS_TOKEN_RESPONSE));

    const apiReq = req.clone({
      url: `${baseUrl}${req.url}`,
      setHeaders: {
        Authorization: `Bearer ${token.id_token}`,
        'rmd-user-agent':'DSFE'
      }
    });

    return next.handle(apiReq);
  }
}
