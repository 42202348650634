import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html'
})
export class PagerComponent implements OnInit {
  @Input() count: number;
  totalPages: number;
  currentPage = 1;
  pageSize = 5;

  constructor() {
  }

  ngOnInit() {
    this.totalPages = Math.ceil(this.count / this.pageSize);
  }

  next() {

  }

  previous() {

  }
}
