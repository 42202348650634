import {Component, OnDestroy, OnInit} from '@angular/core';
import {DocumentModel} from '@app/model/document.model';
import {AppendixCategory, AppendixItem, Attachment} from '@app/model/appendix.model';
import {EventService} from '@app/services/event.service';
import {BaseComponent} from '@app/components/base/base.component';
import {ConfigUtils} from '@app/utils/common/Config-Util';
import {ActivatedRoute, Router} from '@angular/router';
import {PageDefinitionService} from '@app/services/page-definition.service';
import {Constants} from '@app/utils/constants/constants';
import {CommonUtils} from '@app/utils/common/common-utils';
import {CKEditor4} from 'ckeditor4-angular';
import {ShowdownConverter} from 'ngx-showdown';
import {Subscription} from 'rxjs';
import {AdminService} from '@app/services/admin.service';
import uid from 'uid';
import {AuthService} from '@app/services/auth.service';
import {MzModalService} from 'ngx-materialize';
import {AppendixComponent} from '@app/components/appendix/appendix/appendix.component';
import * as _ from 'lodash';
import {ConfirmModalService} from '@app/services/confirm-modal.service';

@Component({
  selector: 'app-edit-appendix',
  templateUrl: './edit-appendix.component.html',
  styleUrls: ['./edit-appendix.component.scss']
})
export class EditAppendixComponent extends BaseComponent implements OnInit, OnDestroy {

  loading = true;
  public appendix: AppendixItem;
  public descriptionHtml: string;
  public categories: Array<AppendixCategory>;
  config = ConfigUtils.getEditorConfig();
  saveSubscription: Subscription;
  revertSubscription: Subscription;
  queueSubscription: Subscription;
  deleteSubscription: Subscription;
  loadSubscription: Subscription;
  id: string;
  selectedFileName: string;
  public selectedFile: string;

  constructor(private docService: EventService<DocumentModel>, private route: ActivatedRoute, private pageDefService: PageDefinitionService,
              private showdownConverter: ShowdownConverter, private adminService: AdminService,
              private router: Router, private authService: AuthService, private modalService: MzModalService,
              private confirmModalService: ConfirmModalService) {
    super();
  }

  ngOnInit() {
    this.initAdminEventHandlers();
    this.loading = true;
    this.pageDefService.getAppendixCategories(CommonUtils.getYear()).subscribe(cats => {
      this.categories = cats;
      this.reload();
    });

    this.loadSubscription = this.docService.getLoadingEvent().subscribe((val) => {
      if (val === 'reload') {
        this.router.navigate(['/home/page/999']);
      }
    });


  }

  ngOnDestroy(): void {
    this.revertSubscription.unsubscribe();
    this.saveSubscription.unsubscribe();
    this.queueSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.loadSubscription.unsubscribe();
  }

  reload() {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id === 'new') {
        this.appendix = new AppendixItem('', 'memo', 'Memo Accounts', '', '', [],
          CommonUtils.getYear(), CommonUtils.getCompany(), true, 0, Constants.NEW_STATUS, '', '', '');
        const doc = DocumentModel.initFromAppendix(this.appendix, 'Appendix');
        this.docService.emit(doc);
        this.loading = false;
        this.docService.emitReload('');
      } else {
        this.pageDefService.getAppendixById(this.id).subscribe(value => {
          this.appendix = value;
          this.appendix.descriptionHtml = this.showdownConverter.makeHtml(this.appendix.description);
          const doc = DocumentModel.initFromAppendix(this.appendix, 'Appendix');
          this.docService.emit(doc);
          this.loading = false;
          this.docService.emitReload('');
        });
      }
    });
  }

  private initAdminEventHandlers() {
    this.revertSubscription = this.adminService.revertClicked.subscribe(() => this.revert());
    this.saveSubscription = this.adminService.saveAsDraftClicked.subscribe(() => this.save());
    this.queueSubscription = this.adminService.queueClicked.subscribe(() => this.queue());
    this.deleteSubscription = this.adminService.deleteClicked.subscribe(() => this.delete());
  }

  descriptionChanged($event: CKEditor4.EventInfo) {

    // this.appendix.description = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  save() {
    this.loading = true;
    for (let category of this.categories) {
      if (category.category === this.appendix.category) {
        this.appendix.categoryName = category.categoryName;
        break;
      }
    }
    this.pageDefService.saveAppendix(this.appendix).subscribe((data) => {
      if (this.id === 'new') {
        this.router.navigate(['/admin/edit/appendix/' + data.id]);
      } else {
        this.reload();
      }
    });
  }

  delete() {
    this.confirmModalService.delete(this.appendix.id);
  }

  queue() {
    this.loading = true;
    this.docService.emit(undefined);
    if (this.appendix.publishedId !== null) {
      this.pageDefService.queueAppendix(this.appendix).subscribe((data) => {
        this.reload();
      });
    }
  }

  revert() {
    this.loading = true;
    this.docService.emit(undefined);
    if (this.appendix.publishedId !== null) {
      let isNew = false;
      if (this.appendix.publishedId === '') {
        isNew = true;
      }
      this.pageDefService.revertAppendix(this.appendix).subscribe((data) => {
        if (isNew) {
          this.router.navigate(['/home/page/999']);
        }
        this.reload();
      });
    }
  }

  fileSelected(evt) {
    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (readerEvt) => this.fileLoadComplete(readerEvt);
      reader.readAsDataURL(file);
    }

    this.selectedFileName = this.parseFilename(this.selectedFile);
  }

  private fileLoadComplete(readerEvt) {
    this.appendix.attachments.push(new Attachment(uid(), readerEvt.target.result,
      this.selectedFileName, new Date().toDateString(), this.authService.getUser().LAST_NAME));
    // display the selected image
    // this.overviewPage.overviewImage = readerEvt.target.result;
    //
    // // capture the base64-encoded image data for saving
    // const dataUrlPrefix = 'data:image/png;base64,';
    // this.imgData = this.overviewPage.overviewImage.substring(dataUrlPrefix.length);
  }

  private parseFilename(filePath: string) {
    if (!filePath) {
      return '';
    }
    const start = filePath.indexOf('\\') >= 0 ? filePath.lastIndexOf('\\') : filePath.lastIndexOf('/');
    const filename = filePath.substring(start);
    return (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) ? filename.substring(1) : filename;
  }

  preview() {
    this.modalService.open(AppendixComponent, {item: this.appendix});
  }

  changeDescription($event) {
    this.appendix.description = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  deleteAttachment(id: string) {
    _.remove(this.appendix.attachments, {
      id
    });
  }
}
