import {Component, OnInit} from '@angular/core';
import { CommonUtils } from '@app/utils/common/common-utils';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html'
})
export class BaseComponent implements OnInit {
  theme = CommonUtils.getCompany().toLocaleLowerCase();
  company = CommonUtils.getCompany();
  year = CommonUtils.getYear();
  hideSubHeader = false;
  constructor() {
  }

  ngOnInit() {
  }
  async delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
