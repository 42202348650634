import {Component, OnInit} from '@angular/core';
import {CommonUtils} from '@app/utils/common/common-utils';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {
  pdfSrc: any;

  constructor(public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const uri = sessionStorage.getItem(decodeURI(params.src));
      this.pdfSrc = uri;
    });
  }

}
