import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private cancelClickedSource = new Subject<void>();
  private saveAsDraftClickedSource = new Subject<void>();
  private queueClickedSource = new Subject<void>();
  private revertClickedSource = new Subject<void>();
  private deleteClickedSource = new Subject<void>();
  private publishClickedSource = new Subject<void>();

  cancelClicked = this.cancelClickedSource.asObservable();
  saveAsDraftClicked = this.saveAsDraftClickedSource.asObservable();
  queueClicked = this.queueClickedSource.asObservable();
  revertClicked = this.revertClickedSource.asObservable();
  deleteClicked = this.deleteClickedSource.asObservable();
  publishClicked = this.publishClickedSource.asObservable();

  constructor(private router: Router) {
  }

   naviaback(execute: boolean)
   {
     if(execute)
     {
     if(localStorage.getItem('tabId') === null || localStorage.getItem('tabId') === '0'  || localStorage.getItem('tabId') === '999')
       {
         this.router.navigate([`home/overview`]);
       }
       else {
         this.router.navigate([`home/page/` + localStorage.getItem('tabId')]);
       }
     }
   }

  raiseCancelClicked(execute: boolean) {
    if (execute) {
      this.cancelClickedSource.next();
    }
  }

  raiseSaveAsDraftClicked(execute: boolean) {
    if (execute) {
      this.saveAsDraftClickedSource.next();
    }
  }

  raiseQueueClicked(execute: boolean) {
    if (execute) {
      this.queueClickedSource.next();
    }
  }

  raiseRevertClicked(execute: boolean) {
    if (execute) {
      this.revertClickedSource.next();
    }
  }

  raiseDeleteClicked(execute: boolean) {
    if (execute) {
      this.deleteClickedSource.next();
    }
  }

  raisePublishClicked(execute: boolean) {
    if (execute) {
      this.publishClickedSource.next();
    }
  }
}
