import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {AdminService} from '@app/services/admin.service';
import {EventService} from '@app/services/event.service';
import {DocumentModel} from '@app/model/document.model';
import {Constants} from '@app/utils/constants/constants';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-admin-toolbar',
  templateUrl: './admin-toolbar.component.html'
})
export class AdminToolbarComponent extends BaseComponent implements OnInit, OnChanges {
  revertClass = 'disabled';
  queueClass = 'disabled';
  deleteClass = 'disabled';
  toolbarHidden = false;
  @Input() document: DocumentModel;

  constructor(public adminService: AdminService, public route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.resetClasses();
    // TODO: This was a quick and dirty way to do this
    if (((this.route.component as any).name.toString()) === 'EditAccountDetailsComponent') {
      this.toolbarHidden = (this.route.component as any).name.toString() === 'ArchiveSettingsComponent';
    } else {
      this.toolbarHidden = (this.route.firstChild.component as any).name.toString() === 'ArchiveSettingsComponent';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetClasses();
  }

  resetClasses() {
    if (this.document) {
      if (this.document.status === 'P') {
        this.revertClass = 'disabled';
        this.queueClass = 'disabled';
        if (this.document.type === 'Appendix') {
          this.deleteClass = '';
        } else {
          this.deleteClass = 'disabled';
        }
      } else if (this.document.status === Constants.DRAFT_STATUS || this.document.status === Constants.REMOVE_STATUS) {
        this.revertClass = '';
        this.queueClass = '';
        this.deleteClass = 'disabled';
      } else if (this.document.status === Constants.QUEUED_STATUS) {
        this.revertClass = '';
        this.queueClass = 'disabled';
        this.deleteClass = 'disabled';
      }
    } else {
      this.revertClass = 'disabled';
      this.queueClass = 'disabled';
      this.deleteClass = 'disabled';
    }
  }
}
