import {AccountDetails} from '@app/model/account.details.model';
import {Page} from '@app/model/page.model';
import {Account} from '@app/model/account.model';
import {AppendixItem, AppendixModel} from '@app/model/appendix.model';

export class DocumentModel {
  constructor(
    public status: string,
    public title: string,
    public timestamp: string,
    public statusText: string,
    public user: string,
    public type?: string
  ) {
  }

  public static initFromAccountDetails(accountDetails: AccountDetails, type: string) {

    return new DocumentModel(accountDetails.status,
      `${accountDetails.pageName} / ${accountDetails.category}`,
      new Date(Number(accountDetails.editedDate)).toLocaleString(),
      DocumentModel.statusTextFromCode(accountDetails.status), accountDetails.editedBy, accountDetails.accountName);
  }

  public static initFromPageDefinition(page: Page, type: string) {
    return new DocumentModel(page.status,
      page.title,
      new Date(Number(page.editedDate)).toLocaleString(),
      DocumentModel.statusTextFromCode(page.status), page.editedBy, type);
  }

  public static initFromOverview(overview: Page, type: string) {
    return new DocumentModel(
      overview.status,
      overview.title,
      new Date(Number(overview.editedDate)).toLocaleString(),
      DocumentModel.statusTextFromCode(overview.status),
      overview.editedBy, type
    );
  }

  public static initFromAccount(account: Account) {
    return new DocumentModel(account.status,
      `Edit Line Item`,
      new Date(Number(account.editedDate)).toLocaleString(),
      DocumentModel.statusTextFromCode(account.status), account.editedBy);
  }

  public static initFromAppendix(appendix: AppendixItem, type: string) {
    return new DocumentModel(appendix.status,
      'Appendix',
      new Date(Number(appendix.editedDate)).toLocaleString(),
      DocumentModel.statusTextFromCode(appendix.status), appendix.editedBy, type);
  }

  private static statusTextFromCode(statusCode: string) {
    let statusText = 'Unknown';

    switch (statusCode) {
      case 'P':
        statusText = 'Published';
        break;
      case 'Q':
        statusText = 'Queued';
        break;
      case 'D':
        statusText = 'Saved as Draft';
        break;
      case 'R':
        statusText = 'Saved as Draft';
        break;
    }

    return statusText;
  }
}
