import {Component, OnInit} from '@angular/core';
import {EventService} from '@app/services/event.service';
import {Constants} from '@app/utils/constants/constants';
import {CommonUtils} from '@app/utils/common/common-utils';
import {ArchiveYearsService} from '@app/services/archive-years.service';
import {AccountDetailsService} from '@app/services/account-details.service';
import {AccountDetails} from '@app/model/account.details.model';
import {AccountlistModel} from '@app/model/accountlist.model';
import {CopyComponent} from '@app/components/home/header/copy/copy.component';
import {MzModalService} from 'ngx-materialize';
import {ToggleComponent} from '@app/components/home/header/toggle/toggle.component';
import {ActionService} from '@app/services/action.service';
import {BaseComponent} from '@app/components/base/base.component';

@Component({
  selector: 'app-archive-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent extends BaseComponent implements OnInit {
  loading = true;
  accountList: Array<AccountlistModel>;
  archiveList: Array<AccountlistModel>
  theme = CommonUtils.getCompany().toLocaleLowerCase();
  constructor(public service: ArchiveYearsService, private eventService: EventService<string>,
              public modalService: MzModalService, public actionService: ActionService) {
    super();
  }

  ngOnInit() {
    this.initActionsEventHandlers();
    this.loadData();
  }

  loadData(expectedActiveYear?: string, predicate?: (item) => {}) {
    this.loading = true;
    this.service.getArchiveYears().subscribe((data) => {
      if (expectedActiveYear) {
        this.delay(1000).then(() => {
          const actives = data.filter(predicate);
          if (actives.length === 0 || actives.length > 1 || (actives.length === 1 && actives[0].year !== expectedActiveYear)) {
            this.loadData(expectedActiveYear, predicate);
          } else {
            this.accountList = data;
            this.loading = false;
          }
        });
      } else {
        this.accountList = data;
        this.archiveList = this.accountList.filter(value => value.status === Constants.ARCHIVED_STATUS);
        this.loading = false;
      }
    });
  }

  createCopy(year: string) {
    this.modalService.open(CopyComponent, {year});
  }

  toggleLock(year: string, status: string, type: string) {
    this.modalService.open(ToggleComponent, {year, status, type});
  }

  toggleLockCallback(year: string, status: string) {
    if (status !== 'L') {
      this.service.LockYear(year).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.loadData(year, (item) => item.status === 'L');
        }
      });
    } else {
      this.service.UnlockYear(year).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.loadData(year, (item) => item.status === 'P');
        }
      });
    }
  }


  toggleActive(year: string, type: string) {

    this.modalService.open(ToggleComponent, {year, type});
  }

  toggleActiveCallback(year: string) {
    this.service.setActiveYear(year).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.loadData(year, (item) => item.isActive === 'Y');
      }
    });
  }

  archiveManual(year: string, type: string) {
    this.modalService.open(ToggleComponent, {year, type});
  }

  archiveManualCallback(year: string) {
    this.service.setArchiveYear(year).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.loadData(year, (item) => item.status === 'A');
      }
    });
  }
  getDate(ephochTime) {
    return new Date(parseInt(ephochTime, null)).toDateString();
  }

  private initActionsEventHandlers() {
    this.actionService.activationClicked.subscribe((year) => this.toggleActiveCallback(year));
    this.actionService.lockToggleClicked
      .subscribe((data: { year: string, status: string }) => this.toggleLockCallback(data.year, data.status));
    this.actionService.archiveClicked
      .subscribe((year: string) => this.archiveManualCallback(year));
  }
}
