import {Category} from '@app/model/category.model';
import {Constants} from '@app/utils/constants/constants';

export class AppendixModel {
  constructor(
    public categoryName: string,
    public company: string,
    public link: string,
    public data: Array<AppendixItem>,
    public title: string,
    public status?: string,
    public editedBy?: string,
    public editedDate?: string
  ) {
  }

}

export class AppendixItem {
  descriptionHtml: string;
  constructor(
    public id: string,
    public category: string,
    public categoryName: string,
    public title: string,
    public description: string,
    public attachments: Array<Attachment>,
    public year: string,
    public company: string,
    public isActive: boolean,
    public sortOrder: number,
    public status: string,
    public publishedId: string,
    public editedBy: string,
    public editedDate: string
  ) {
  }

}

export class Attachment {
  constructor(
    public id: string,
    public url: string,
    public documentName: string,
    public dateUploaded: string,
    public uploadedBy: string
  ) {
  }
}
export class AppendixCategory {

  constructor(
    public id: string,
    public category: string,
    public categoryName: string,
    public year: string,
    public company: string,
    public isActive: boolean,
    public isDefault: boolean,
    public status: string,
    public publishedId: string
  ) {
  }

}
