export class ConfigUtils {

  constructor() {
  }

  static getEditorConfig() {
    return {
      toolbar: [
        {
          name: 'default',
          items: [ 'Format', '-', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList' ]
        }
      ],
      removePlugins: 'elementspath',
      resize_enabled: false
    };
  }
}
