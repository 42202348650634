import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html'
})
export class TabMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
