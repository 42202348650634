import {Component, OnInit} from '@angular/core';
import {Constants} from '@app/utils/constants/constants';
import {PageDefinitionService} from '@app/services/page-definition.service';
import {CommonUtils} from '@app/utils/common/common-utils';
import {AuthService} from '@app/services/auth.service';
import {EventService} from '@app/services/event.service';
import {AccountManual} from '@app/model/account.manual.model';
import {AccountManualService} from '@app/services/account-manual.service';
import {Page} from '@app/model/page.model';
import {Company} from '@app/utils/company';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-rmd-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  loading = true;
  theme;
  accountManual: AccountManual;
  subscription: Subscription;
  logSubscription: Subscription;
  version: string;
  publishDte: string;

  constructor(public authService: AuthService, public service: PageDefinitionService,
              public accountManualService: AccountManualService, public eventService: EventService<string>) {

  }

  callback() {
    this.loading = false;
  }

  ngOnInit(): void {
    this.subscription = this.eventService.getLoadingEvent()
      .subscribe(() => this.reloadData());
    this.logSubscription = this.eventService.getLogoutEvent().subscribe(value => {
      if (value.logout) {
        this.authService.logout();
      }
    });
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.logSubscription.unsubscribe();
  }

  reloadData() {
    this.loading = true;
    this.loadData();

  }

  loadData() {
    const editModeFlag = this.authService.getEditModeFlag();
    const url = window.location.hostname;
    // FOR LOCAL DEBUGGING
    // const companyTheme = localStorage.getItem('company_theme');
    // if (environment.stage === 'local' && companyTheme) {
    //   url = url.replace('localhost', 'dev.fsacctmanual.' + companyTheme + '.com');
    // }

    if (url.includes('lexus')) {
      localStorage.setItem(Constants.COMPANY, Company[Company.Lexus]);
    } else {
      localStorage.setItem(Constants.COMPANY, Company[Company.Toyota]);
    }
    this.theme = CommonUtils.getCompany().toLocaleLowerCase();
    if (!editModeFlag) {
      this.accountManualService.getCurrentAccountManual().subscribe((data) => {
        this.accountManual = data;
        this.version = this.accountManual.version;
        this.publishDte = new Date(Number(this.accountManual.editedDate)).toLocaleString();
        localStorage.setItem(Constants.VERSION, this.accountManual.version);
        localStorage.setItem(Constants.PUBLISH_DTE, this.accountManual.editedDate);
        localStorage.setItem(Constants.ACCOUNT_MANUAL_YEAR, this.accountManual.year);
        this.service.getAllPageDefinitions(CommonUtils.getYear(), this.callback.bind(this));
      });
    } else {
      this.service.getAllPageDefinitionsForEdit().subscribe(result => {
        const pages: Array<Page> = result;
        localStorage.setItem(Constants.PAGE_DEFINITIONS, JSON.stringify(pages));

        this.service.getAppendix(CommonUtils.getYear()).subscribe(appdx => {
          localStorage.setItem(Constants.APPENDIX_DATA, JSON.stringify(appdx));
          this.loading = false;
        });
        // this.authService.setReauthorize(false);
      });
    }
  }
}
