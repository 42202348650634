export class Constants {
  public static PAGE_DEFINITIONS = 'page-definitions';
  public static PAGE_NEW_ACCOUNTS = 'PAGE_NEW_ACCOUNTS';
  public static APPENDIX_DATA = 'appendix';
  public static SAVE_APPENDIX = `admin/appendix/draft`;
  public static QUEUE_APPENDIX = `admin/appendix/queue`;
  public static REVERT_APPENDIX = `admin/appendix/revert`;
  public static DELETE_APPENDIX = `admin/appendix/delete`;
  public static SORT_ACCOUNTS = `admin/accounts/sort`;
  public static SORT_APPENDIX = `admin/appendix/sort`;
  public static PUBLISH_APPENDIX = `admin/publish/appendix`;
  public static ACCOUNT_MANUAL_YEAR = 'account-manual-year';
  public static COMPANY = 'company';
  public static SELECT_TAB = 'select_tab';
  public static REDIRECT = 'redirect';
  public static TAB_ID = 'tabId';
  public static VERSION = 'version';
  public static PUBLISH_DTE = 'publish_dte';
  public static PUBLISH_DATA = 'publish-data';

  public static EDIT_MODE_FLAG = 'EDIT_MODE';
  public static EDIT_COMPANY = 'EDIT_COMPANY';
  public static EDIT_YEAR = 'EDIT_YEAR';

  public static SUCCESS = 'SUCCESS';
  public static INVALID_YEAR = 'INVALID_YEAR';
  public static DRAFT_STATUS = 'D';
  public static DRAFT_REMOVE_STATUS = 'D,R';
  public static NEW_STATUS = 'N';
  public static REMOVE_STATUS = 'R';
  public static QUEUED_STATUS = 'Q';
  public static PUBLISHED_STATUS = 'P';
  public static ARCHIVED_STATUS = 'A';
  public static LOCKED_STATUS = 'L';
  public static QUEUE_ACTION_REMOVE = 'Remove';
  public static QUEUE_ACTION_QUEUE = 'Queue';
  public static ACCOUNT_DETAILS_URL = `view/account-details/{accountId}/{accountDetailId}`;
  public static ALL_ACCOUNT_DETAILS_URL = `view/account-details/all/{company}/{year}`;
  public static ACCOUNT_DETAILS_FOR_EDIT_URL = `admin/account-details/edit/{accountNumber}/{accountDetailId}`;
  public static ACCOUNTS_BY_DETAIL_ID_URL = `admin/accounts/by-detail-id/{year}/{accountDetailId}`;
  public static SAVE_ACCOUNT_DETAILS_URL = `admin/account-details/draft`;
  public static QUEUE_ACCOUNT_DETAILS_URL = `admin/account-details/queue`;
  public static REVERT_ACCOUNT_DETAILS_URL = `admin/account-details/revert`;
  public static GET_NEW_ACCOUNTS_URL = `view/accounts/new`;
  public static GET_LINES_URL = `view/accounts/get-lines/{year}/{page}/{column}`;
  public static GET_ALL_ACCOUNTS = `view/accounts/get-all-accounts/{year}`;
  public static SEARCH_ACCOUNTS_URL = `view/account-details/search/{year}/{searchText}`;
  public static ALL_PAGE_DEFINITIONS_URL = `view/pages/{year}`;
  public static APPENDIX = `view/appendix/{year}/{forEdit}`;
  public static APPENDIX_CATEGORIES = `/view/appendix-categories/{year}`;
  public static APPENDIX_ID = `view/appendix/get/{id}`;
  public static SAVE_PAGE_DEFINITIONS_URL = 'admin/pages/draft';
  public static PAGE_DEFINITIONS_FOR_EDIT_URL = 'admin/pages/edit/{year}';
  public static QUEUE_PAGE_DEFINITIONS_URL = 'admin/pages/queue';
  public static REVERT_PAGE_DEFINITIONS_URL = 'admin/pages/revert';
  public static GET_ACCOUNT_MANUALS_URL = 'admin/manuals';
  public static GET_CURRENT_ACCOUNT_MANUAL_URL = 'view/manuals/current';
  public static COPY_ACCOUNTING_MANUAL_URL = 'admin/manuals/copy';
  public static ACCOUNTS_FOR_EDIT_LINE_ITEM_URL = `admin/accounts/edit/{year}/{pageNumber}/{lineNumber}/{columnNumber}`;
  public static SAVE_ACCOUNTS_URL = `admin/accounts/draft`;
  public static QUEUE_ACCOUNTS_URL = `admin/accounts/queue`;
  public static REVERT_ACCOUNTS_URL = `admin/accounts/revert`;
  public static GET_YEAR_BY_BRAND_URL = 'admin/get-year-by-brand';
  public static GET_ARCHIVE_YEARS = 'admin/manuals/get-years';
  public static SET_ACTIVE_YEAR = 'admin/manuals/activate-year';
  public static SET_LOCK_YEAR = 'admin/manuals/lock-year';
  public static SET_UNLOCK_YEAR = 'admin/manuals/unlock-year';
  public static SET_ARCHIVE_YEAR = 'admin/manuals/archive-year';
  public static ACCOUNTS_URL_PREFIX = '/accounts/';
  public static readonly ACCOUNTSUMMARY = {
    PAGE : 'Page Definition',
    LINEITEM : 'Line Item',
    ACCOUNTDEFINITION : 'Account Definition'
  };

  // Publish Urls
  public static GET_PUBLISH_SUMMARY = `view/accounts/get-summary/{year}`;
  public static PUBLISH_ALL = `admin/accounts/publish-all`;
  public static REVERT_ALL = `admin/accounts/revert-all`;
  public static QUUEUE_ALL = `admin/accounts/queue-all`;


}
