import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ErrorHandlingService} from '@app/services/error-handling.service';
import {Observable} from 'rxjs';
import {Constants} from '@app/utils/constants/constants';
import {catchError, retry} from 'rxjs/operators';
import { AccountManual } from '@app/model/account.manual.model';
import {Response} from '@app/model/response.model';

@Injectable({
  providedIn: 'root'
})
export class AccountManualService {

  constructor(private http: HttpClient, private errorHandlingService: ErrorHandlingService) { }

  public getAccountManuals(): Observable<Array<AccountManual>> {
    return this.http.get<Array<AccountManual>>(Constants.GET_ACCOUNT_MANUALS_URL).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getCurrentAccountManual(): Observable<AccountManual> {
    return this.http.get<AccountManual>(Constants.GET_CURRENT_ACCOUNT_MANUAL_URL).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public copyAccountManual(year: string, nextYear: string): Observable<Response> {
    const body = {
      year, nextYear
    };

    return this.http.post<Response>(Constants.COPY_ACCOUNTING_MANUAL_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
}
