import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { SearchAccountsService } from '@app/services/search-accounts.service';
import { AccountDetailsComponent } from '@app/components/account-details/account-details.component';
import { MzModalService } from 'ngx-materialize';
import { AccountDetails } from '@app/model/account.details.model';
import { BaseComponent } from '@app/components/base/base.component';
import {CommonUtils} from '@app/utils/common/common-utils';

@Component({
  selector: 'app-account-search-list',
  templateUrl: './am-search.component.html'
})
export class AmSearchComponent extends BaseComponent implements OnInit {
  searchList: Array<AccountDetails>;
  count: number;
  searchText: string;
  constructor(public router: Router, public service: SearchAccountsService,
              public modalService: MzModalService, public route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.searchText = params.searchTerm;
      this.service.searchAccounts(decodeURI(params.searchTerm),CommonUtils.getYear()).subscribe((data) => {
        this.searchList = data;
        this.count = Object.keys(this.searchList).length;
      });
      this.hideSubHeader = true;
    });
  }

  openAccountDetails(accountNumber, companyName) {
    this.modalService.open(AccountDetailsComponent, {accountName: accountNumber, company: companyName});
  }
}
