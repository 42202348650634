import {
  AfterViewInit,
  Component, ComponentFactory,
  ComponentFactoryResolver, ComponentRef, ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {AuthService} from '@app/services/auth.service';
import {Router} from '@angular/router';
import {Constants} from '@app/utils/constants/constants';
import {User} from '@app/model/user.model';
import {MzModalService} from 'ngx-materialize';
import {EditComponent} from '@app/components/home/header/edit/edit.component';
import {EventService} from '@app/services/event.service';
import {AccountManual} from '@app/model/account.manual.model';
import {PublishService} from '@app/services/publish.service';
import {PublishModalService} from '@app/services/publish-modal.service';
import {CopyComponent} from '@app/components/home/header/copy/copy.component';
import {CommonUtils} from '@app/utils/common/common-utils';
import html2pdf from 'html2pdf.js';
import {AmPdfExportComponent} from '@app/components/am-pdf-export/am-pdf-export.component';
import {AccountDetailsService} from '@app/services/account-details.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('pdfContainer', {read: ViewContainerRef, static: true}) container;
  @Input() accountManual: AccountManual;
  user: User;
  isSearch: boolean;
  headerText: string;
  searchTxt: string;
  @Input() version: string;
  @Input() publishDte: string;
  componentRef: ComponentRef<any>;

  // year: string;

  constructor(public authService: AuthService, public router: Router, public modalService: MzModalService,
              private eventService: EventService<string>,
              private publishService: PublishService,
              private publishModalService: PublishModalService, private resolver: ComponentFactoryResolver,
              private service: AccountDetailsService) {
    super();
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.isSearch = location.href.includes('search');
    if (null !== localStorage.getItem(Constants.VERSION)) {
      this.version = localStorage.getItem(Constants.VERSION);
      this.publishDte = new Date(Number(localStorage.getItem(Constants.PUBLISH_DTE))).toLocaleString();
    }
    this.headerText = this.isSearch ? 'Back to accounting manual' : this.company + ' Accounting Manual';
    this.eventService.getYear().subscribe(value => {
      this.year = CommonUtils.getYear();
    });
    if (this.router.url.includes('/search/')) {
      this.searchTxt = decodeURI(decodeURI(this.router.url.replace('/search/', '')));
      this.search();
    }
    this.eventService.allAccountDetailsEvent.subscribe((s) => {
      const details = JSON.parse(s);
      this.container.clear();
      const factory = this.resolver.resolveComponentFactory(AmPdfExportComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.accountDetailsList = details;
    });
  }

  ngAfterViewInit(): void {
  }

  getTabId() {
    let tabId = localStorage.getItem(Constants.TAB_ID);
    if (tabId === null || tabId === undefined) {
      tabId = '0';
    }
    return parseInt(tabId, null);
  }

  navigate() {
    if (!this.isSearch) {
      const tabs = $('.tabs a');
      if (tabs.length > 0) {
        tabs[0].click();
      }
    } else {
      const navigateUrl = this.getTabId() === 0 ? '/home/overview' : '/home/page/' + this.getTabId();
      this.router.navigate([navigateUrl]);
    }
  }

  openUserInfo() {
    $('#userInfoModal').modal('open');
  }

  logout() {
    this.authService.logout();
  }

  search() {
    this.router.navigate([`/search/${decodeURI(this.searchTxt)}`]);
  }

  toggleEditMode() {
    if (this.authService.getEditModeFlag()) {
      localStorage.setItem(Constants.EDIT_MODE_FLAG, 'false');
      this.eventService.emit('true');
      this.eventService.emitYear(CommonUtils.getYear());
    } else {
      this.modalService.open(EditComponent);
    }
  }

  exportPDF() {
    const data = this.componentRef.location.nativeElement;
   /* const worker = html2pdf().from(data.children[0].children[0]).save('test.pdf');*/
    var worker = html2pdf().from(data.children[0].children[0].children[0]).toPdf();
    for(let i=1; i < this.componentRef.instance.accountDetailsList.length; i++) {
      worker = worker.from(data.children[0].children[0].children[i].children[0]).toContainer().toCanvas().toPdf().get('pdf').then((pdf) =>
      {
        if (i < this.componentRef.instance.accountDetailsList.length - 1) { // Bump cursor ahead to new page until on last page
          pdf.addPage();
        }
      });
    }
    worker = worker.save("AM"+CommonUtils.getCompany()+".pdf");
  }

  publishManual() {
    // this.publishModalService.publishManual();
    this.router.navigate([`admin/publish`]);

  }

  copyManual() {
    this.modalService.open(CopyComponent);
  }

  archiveSetting() {
    this.router.navigate([`admin/settings`]);
  }
}
