import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Account} from '@app/model/account.model';
import {AccountManual} from '@app/model/account.manual.model';
import {HttpClient} from '@angular/common/http';
import {ErrorHandlingService} from '@app/services/error-handling.service';
import {Response} from '@app/model/response.model';
import {Constants} from '@app/utils/constants/constants';
import {catchError, retry} from 'rxjs/operators';
import {AccountDetails} from '@app/model/account.details.model';
import {AccountlistModel} from '@app/model/accountlist.model';

@Injectable({
  providedIn: 'root'
})
export class ArchiveYearsService {

  constructor(private http: HttpClient, private errorHandlingService: ErrorHandlingService) {
  }
  // public copyAccountManual(year: string, nextYear: string): Observable<Response>
  public getArchiveYears(): Observable<Array<AccountlistModel>> {
        return this.http.get<Array<AccountlistModel>>((Constants.GET_ARCHIVE_YEARS)).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public setActiveYear(year: string): Observable<Response> {
    const body = {
      year
    };

    return this.http.post<Response>(Constants.SET_ACTIVE_YEAR, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public setArchiveYear(year: string): Observable<Response> {
    const body = {
      year
    };

    return this.http.post<Response>(Constants.SET_ARCHIVE_YEAR, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public LockYear(year: string): Observable<Response> {
    const body = {
      year
    };

    return this.http.post<Response>(Constants.SET_LOCK_YEAR, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public UnlockYear(year: string): Observable<Response> {
    const body = {
      year
    };

    return this.http.post<Response>(Constants.SET_UNLOCK_YEAR, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
}
