import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AdminService} from '@app/services/admin.service';
import {ActivatedRoute} from '@angular/router';
import {BaseComponent} from '@app/components/base/base.component';
import {Constants} from '@app/utils/constants/constants';
import {EventService} from '@app/services/event.service';
import {DocumentModel} from '@app/model/document.model';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-edit-container',
  templateUrl: './edit-container.component.html'
})
export class EditContainerComponent extends BaseComponent implements OnInit, OnChanges {
  revertClass = 'disabled';
  queueClass = 'disabled';
  toolbarHidden = false;
  document;
  loading = true;
  editSubscription: Subscription;
  loadSubscription: Subscription;

  constructor(public adminService: AdminService, public route: ActivatedRoute, public docService: EventService<DocumentModel>) {
    super();
  }

  ngOnInit() {
    this.editSubscription = this.docService.getEditEvent().subscribe((doc) => {
      this.document = doc;
      this.loadSubscription = this.docService.getLoadingEvent().subscribe(() => {
        this.loading = false;
        this.resetClasses();
      });
    });
    this.resetClasses();
    // TODO: This was a quick and dirty way to do this
    if (((this.route.component as any).name.toString()) === 'EditAccountDetailsComponent') {
      this.toolbarHidden = (this.route.component as any).name.toString() === 'ArchiveSettingsComponent';
    } else {
      this.toolbarHidden = (this.route.firstChild.component as any).name.toString() === 'ArchiveSettingsComponent';
    }
  }

  ngOnDestroy(): void {
    this.editSubscription.unsubscribe();
    this.loadSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetClasses();
  }

  resetClasses() {
    if (this.document) {
      if (this.document.status === 'P') {
        this.revertClass = 'disabled';
        this.queueClass = 'disabled';
      } else if (this.document.status === Constants.DRAFT_STATUS || this.document.status === Constants.REMOVE_STATUS) {
        this.revertClass = '';
        this.queueClass = '';
      } else if (this.document.status === Constants.QUEUED_STATUS) {
        this.revertClass = '';
        this.queueClass = 'disabled';
      }
    } else {
      this.revertClass = 'disabled';
      this.queueClass = 'disabled';
    }
  }

}
