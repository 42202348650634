import {Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {Page} from '@app/model/page.model';
import {AuthService} from '@app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonUtils} from '@app/utils/common/common-utils';
import {AdminService} from '@app/services/admin.service';
import {PublishService} from '@app/services/publish.service';
import {PublishModalService} from '@app/services/publish-modal.service';
import {MzModalService} from 'ngx-materialize';
import {EventService} from '@app/services/event.service';
import {EditLineItemComponent} from '@app/components/page-definitions/edit/edit-line-item/edit-line-item.component';

@Component({
  selector: 'app-page-display',
  templateUrl: './page-definitions.component.html'
})
export class PageDefinitionsComponent extends BaseComponent implements OnInit, OnDestroy {
  loading = false;
  pageDefinition: Page;
  id: string;
  private publishClickedSubscription;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public authService: AuthService,
              private publishService: PublishService,
              private publishModalService: PublishModalService,
              public adminService: AdminService) {
    super();
  }

  ngOnInit() {
    this.initAdminEventHandlers();

    this.hideSubHeader = false;
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.pageDefinition = CommonUtils.getPageWithAccounts(this.id);
    });
  }

  ngOnDestroy(): void {
    this.publishClickedSubscription.unsubscribe();
  }

  editPageDefinition() {
    this.router.navigate([`/admin/edit/page-definitions/` + this.id]);
  }

  isPublishable(): boolean {
    return this.pageDefinition && this.pageDefinition.hasQueuedContent();
  }

  private initAdminEventHandlers() {
    this.publishClickedSubscription =
      this.adminService.publishClicked.subscribe(() => {
        this.publishModalService.publishPage(this.pageDefinition.id, this.pageDefinition.pageNumber);
      });
  }

}
