import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import Callback = JQuery.Deferred.Callback;

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent implements OnInit {

  @Input() value: any;
  @Input() altValue: any;
  @Input() trueValue: any;
  @Input() falseValue: any;
  @Input() text: any;
  @Input() classList: string;
  @Input() cb: (v: any) => {};
  @Input() data: any;
  @Input() record: any;

  propertyValue: string;

  @Output()
  propertyChange = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  @Input()
  get property() {
    return this.propertyValue;
  }

  set property(val: string) {
    this.propertyCall(val);
  }

  propertyCall(val: string) {
    this.propertyValue = val;
    this.propertyChange.emit(this.propertyValue);
  }

  toggle(select: boolean) {
    this.propertyCall(select ? 'Y' : 'N');
    if (this.cb) {
      this.cb(select);
    }
  }

}
