import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {CommonUtils} from '@app/utils/common/common-utils';
import {AuthService} from '@app/services/auth.service';
import {User} from '@app/model/user.model';
import {AdminService} from '@app/services/admin.service';
import {Constants} from '@app/utils/constants/constants';
import {EditComponent} from '@app/components/home/header/edit/edit.component';
import {EventService} from '@app/services/event.service';
import {MzModalService} from 'ngx-materialize';
import {DocumentModel} from '@app/model/document.model';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  providers: []
})
export class AdminHeaderComponent extends BaseComponent implements OnInit {
  theme;
  user: User;
  company = CommonUtils.getCompany();
  year = CommonUtils.getYear();
  document: DocumentModel;
  editSubscription: Subscription;

  constructor(public authService: AuthService, private adminService: AdminService,
              private eventService: EventService<string>,
              public modalService: MzModalService,
              public docService: EventService<DocumentModel>, private location: Location, private router: Router) {
    super();
  }


  ngOnInit() {
    this.theme = CommonUtils.getCompany().toLocaleLowerCase();
    this.user = this.authService.getUser();
    this.editSubscription = this.docService.getEditEvent().subscribe((doc) => {
      this.document = doc;
    });
  }

  ngOnDestroy(): void {
    this.editSubscription.unsubscribe();
  }

  navBack() {
    //this.adminService.raiseCancelClicked(true);
    //this.location.back();
    this.adminService.naviaback(true);
  }

  toggleEditMode() {
    if (this.authService.getEditModeFlag()) {
      localStorage.setItem(Constants.EDIT_MODE_FLAG, 'false');
      this.eventService.emit('true');
    } else {
      this.modalService.open(EditComponent);
    }
  }

  publishMnl() {
    // this.publishModalService.publishManual();
    this.router.navigate([`admin/publish`]);

  }

}
