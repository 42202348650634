import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {Account} from '@app/model/account.model';
import {AuthService} from '@app/services/auth.service';
import {MzModalService} from 'ngx-materialize';
import {EventService} from '@app/services/event.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {PageDefinitionService} from '@app/services/page-definition.service';
import {Page} from "@app/model/page.model";

@Component({
  selector: 'app-view-accounts',
  templateUrl: './view-accounts.component.html',
  styleUrls: ['./view-accounts.component.scss']
})
export class ViewAccountsComponent extends BaseComponent implements OnInit {
  @Input() header: string;
  @Input() accountsList: Array<Array<Account>>;
  @Input() pageNumber: string;
  @Input() category: string;
  @Input() pageDefinition: Page;
  overlaidLoading = false;

  constructor(public authService: AuthService,
              private pageDefinitionService: PageDefinitionService,
              private modalService: MzModalService,
              private eventService: EventService<string>) {
    super();
  }

  ngOnInit() {
  }

  onDrop($event: CdkDragDrop<string, any>, accounts: Account[][]) {
    this.overlaidLoading = true;
    let newLineAccount = accounts[$event.previousIndex][0];
    let i: number = 0;
    while (!newLineAccount && i < accounts.length) {
      newLineAccount = accounts[i][0];
      i++;
    }
    this.pageDefinitionService.saveAccountsSort($event.currentIndex + 1, $event.previousIndex + 1, newLineAccount).subscribe(value => {
      this.overlaidLoading = false;
      this.eventService.emit('reload');
    });
  }

}
