import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Page} from '@app/model/page.model';
import {catchError, retry} from 'rxjs/operators';
import {ErrorHandlingService} from '@app/services/error-handling.service';
import {Constants} from '@app/utils/constants/constants';
import {Observable} from 'rxjs';
import {Response} from '@app/model/response.model';
import {CommonUtils} from '@app/utils/common/common-utils';
import {AppendixCategory, AppendixItem, AppendixModel} from '@app/model/appendix.model';
import {Account} from '@app/model/account.model';
import {AmPdfExportComponent} from "@app/components/am-pdf-export/am-pdf-export.component";
import {AccountDetailsService} from "@app/services/account-details.service";
import {EventService} from "@app/services/event.service";

@Injectable({
  providedIn: 'root'
})
export class PageDefinitionService {
  constructor(private http: HttpClient, private errorHandlingService: ErrorHandlingService,
              private service: AccountDetailsService, private eventService: EventService<string>) {
  }

  loading: boolean;

  public getAllPageDefinitions(yearVal: string, callback) {
    const url = Constants.ALL_PAGE_DEFINITIONS_URL.replace('{year}', yearVal);

    this.http.get<Array<Page>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    ).subscribe(result => {
      const pages: Array<Page> = result;

      localStorage.setItem(Constants.PAGE_DEFINITIONS, JSON.stringify(pages));
      this.service.getAllAccountDetails(localStorage.getItem('company'), yearVal).subscribe((details) => {
        /*details.forEach((d) => d.pageName = CommonUtils.getPageName(d.pageNo));*/
        this.eventService.emitAllAccountDetails(JSON.stringify(details));
      });
      this.getAppendix(yearVal).subscribe(appdx => {
        localStorage.setItem(Constants.APPENDIX_DATA, JSON.stringify(appdx));
        callback();
      });
    });
  }

  public getAllPageDefinitionsForEdit() {
    const url = Constants.PAGE_DEFINITIONS_FOR_EDIT_URL.replace('{year}', CommonUtils.getYear());

    return this.http.get<Array<Page>>(url).pipe(
      retry(2),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getAppendix(yearVal: string) {
    const url = Constants.APPENDIX.replace('{year}', yearVal)
      .replace('{forEdit}', (localStorage.getItem(Constants.EDIT_MODE_FLAG) === 'true' ? '1' : '0'));
    return this.http.get<Array<AppendixModel>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getAppendixCategories(yearVal: string) {
    const url = Constants.APPENDIX_CATEGORIES.replace('{year}', yearVal);
    return this.http.get<Array<AppendixCategory>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getAppendixById(id: string): Observable<AppendixItem> {
    const url = Constants.APPENDIX_ID.replace('{id}', id);
    return this.http.get<AppendixItem>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }


  public savePageDefinitions(year, page: Page): Observable<Response> {
    const body = {
      year, page
    };

    return this.http.post<Response>(Constants.SAVE_PAGE_DEFINITIONS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public queuePageDefinitions(id: string): Observable<Response> {
    const body = {
      id
    };

    return this.http.post<Response>(Constants.QUEUE_PAGE_DEFINITIONS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public revertPageDefinitions(id: string): Observable<Response> {
    const body = {
      id
    };

    return this.http.post<Response>(Constants.REVERT_PAGE_DEFINITIONS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  saveAppendix(appendix: AppendixItem) {
    const body = {
      appendix
    };

    return this.http.post<{ id: string }>(Constants.SAVE_APPENDIX, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  queueAppendix(appendix: AppendixItem) {
    const body = {
      appendix
    };
    return this.http.post<{ id: string }>(Constants.QUEUE_APPENDIX, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  revertAppendix(appendix: AppendixItem) {
    const body = {
      appendix
    };

    return this.http.post<{ id: string }>(Constants.REVERT_APPENDIX, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
  sortAppendix(appendices: Array<AppendixItem>) {
    const body = {
      appendices
    };

    return this.http.post<{ id: string }>(Constants.SORT_APPENDIX, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  deleteAppendix(id: string): Observable<Response> {
    const body = {
      id
    };

    return this.http.post<Response>(Constants.DELETE_APPENDIX, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public saveAccountsSort(current, previous, account: Account) {
    const body = {
      current, previous, account
    };

    return this.http.post<Response>(Constants.SORT_ACCOUNTS, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
}
