import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {AuthService} from '@app/services/auth.service';
import {AppendixItem, AppendixModel} from '@app/model/appendix.model';
import {Constants} from '@app/utils/constants/constants';
import {Router} from '@angular/router';
import {AccountDetailsComponent} from '@app/components/account-details/account-details.component';
import {MzModalService} from 'ngx-materialize';
import {AppendixComponent} from '@app/components/appendix/appendix/appendix.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {$e} from 'codelyzer/angular/styles/chars';
import {PageDefinitionService} from '@app/services/page-definition.service';
import _ from 'lodash';
import {AdminService} from '@app/services/admin.service';
import {PublishModalService} from '@app/services/publish-modal.service';
import {debug} from 'util';

@Component({
  selector: 'app-appendix-list',
  templateUrl: './appendix-list.component.html',
  styleUrls: ['./appendix-list.component.scss']
})
export class AppendixListComponent extends BaseComponent implements OnInit, OnDestroy {

  loading: boolean;
  data: Array<AppendixModel> = [];
  overlaidLoading = false;
  disabledClass: string;
  private publishClickedSubscription;

  constructor(public authService: AuthService, public router: Router, public modalService: MzModalService,
              public pageDefService: PageDefinitionService, public adminService: AdminService,
              public publishModalService: PublishModalService) {
    super();
  }

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem(Constants.APPENDIX_DATA));
    this.resetClasses();
    this.initAdminEventHandlers();
  }

  ngOnDestroy(): void {
    this.publishClickedSubscription.unsubscribe();
  }

  private initAdminEventHandlers() {
    this.publishClickedSubscription =
      this.adminService.publishClicked.subscribe(() => {

        const appendices: Array<AppendixItem> = [];

        for (const item of this.data) {
          for (const i of item.data) {
            if (i.status === Constants.QUEUED_STATUS) {
              appendices.push(i);
            }
          }
        }
        this.publishModalService.publishAppendix(appendices);
      });
  }

  createNewAppendix() {
    this.router.navigate(['/admin/edit/appendix/new']);
  }

  loadAppendix(item: AppendixItem) {
    this.router.navigate(['/admin/edit/appendix/' + ((item.status !== Constants.PUBLISHED_STATUS) ? (item.publishedId !== '' ? item.publishedId : item.id) : item.id)]);
  }

  getAppendixDetails(item: AppendixItem) {

    this.modalService.open(AppendixComponent, {item});
  }

  isDraft(item: AppendixItem): boolean {
    return item.status === Constants.DRAFT_STATUS;
  }

  isQueued(item: AppendixItem): boolean {
    return item.status === Constants.QUEUED_STATUS;
  }

  isDraftOrQueued(item: AppendixItem): boolean {
    return item.status === Constants.DRAFT_STATUS || item.status === Constants.QUEUED_STATUS;
  }

  onDrop($event: CdkDragDrop<string, any>, category: AppendixModel) {
    this.overlaidLoading = true;
    moveItemInArray(category.data, $event.previousIndex, $event.currentIndex);
    category.data.forEach((item, idx) => {
      item.sortOrder = idx + 1;
    });
    this.pageDefService.sortAppendix(category.data).subscribe(value => {
      this.overlaidLoading = false;
    });
  }


  resetClasses() {
    const appendices: Array<AppendixItem> = [];

    for (const item of this.data) {
      for (const i of item.data) {
        appendices.push(i);
      }
    }
    if (_.filter(appendices, {
      status: 'Q'
    }).length > 0) {
      this.disabledClass = '';
    } else {
      this.disabledClass = 'disabled';
    }
  }
}
