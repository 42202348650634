export class Summary {

  public id: string;
  public company: string;
  public year: string;
  public pageNumber: number;
  public summaryDefType: string;

  constructor(
    id: string,
    company: string,
    year: string,
    pageNumber: number,
    summaryDefType: string
  ) {
    this.id = id;
    this.company = company;
    this.year = year;
    this.pageNumber = pageNumber;
    this.summaryDefType = summaryDefType;
  }
}
