import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewChild
} from '@angular/core';
import {TabItemComponent} from '@app/components/home/am-tabs/tab/tab-item/tab-item.component';
import {Constants} from '@app/utils/constants/constants';
import {CommonUtils} from '@app/utils/common/common-utils';
import {TabMenuComponent} from '@app/components/home/am-tabs/tab/tab-menu/tab-menu.component';
import {routes} from "@app/utils/routes";

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html'
})
export class TabComponent implements AfterViewInit {

  @Input() fixedTabWidth: boolean;
  @Input() onShow: () => {};
  @Input() responsiveThreshold: number;
  @Input() swipeable: boolean;
  @Input() theme: string;
  @Input() noMargin: boolean;

  @ViewChild('tabs', null) tabs: ElementRef;
  @ContentChildren(TabItemComponent) tabItems: QueryList<TabItemComponent>;
  @ContentChild(TabMenuComponent, {static: true}) tabMenu: TabMenuComponent;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.initTabs();
  }

  initTabs() {
    const options: Materialize.TabOptions = {
      onShow: this.onShow,
      responsiveThreshold: this.responsiveThreshold,
      swipeable: this.swipeable,
    };

    setTimeout(() => {
      $(this.tabs.nativeElement).tabs(options);
    });
    $('.material-tooltip').removeClass('material-tooltip').addClass(CommonUtils.getCompany().toLocaleLowerCase() + ' material-tooltip');
  }

  selectTab(tabItemId: string) {
    $(this.tabs.nativeElement).tabs(Constants.SELECT_TAB, tabItemId);
    //check if url is /home_
   if(window.location.href.indexOf('home') > 1)
    {
    localStorage.setItem(Constants.TAB_ID, tabItemId);
    }
  }

}
