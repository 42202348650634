import {Component, Input, OnInit} from '@angular/core';
import {MzBaseModal} from 'ngx-materialize';
import {AppendixItem} from '@app/model/appendix.model';
import {CommonUtils} from '@app/utils/common/common-utils';

@Component({
  selector: 'app-appendix',
  templateUrl: './appendix.component.html',
  styleUrls: ['./appendix.component.scss']
})
export class AppendixComponent extends MzBaseModal implements OnInit {

  @Input() item: AppendixItem;
  theme = CommonUtils.getCompany().toLocaleLowerCase();

  constructor() {
    super();
  }

  ngOnInit() {
    console.log(this.item);
  }

  encodeUri(name: string, uri: string) {
    sessionStorage.setItem(name, uri);
    return encodeURI(name);
  }

  downloadURI(uri, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
