import {Component, OnInit} from '@angular/core';
import {MzBaseModal} from 'ngx-materialize';
import {CommonUtils} from '@app/utils/common/common-utils';
import {AccountManualService} from '@app/services/account-manual.service';
import {Constants} from '@app/utils/constants/constants';
import {AuthService} from '@app/services/auth.service';
import {EventService} from '@app/services/event.service';
import {AccountManual} from '@app/model/account.manual.model';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html'
})
export class EditComponent extends MzBaseModal implements OnInit {
  accountManuals: Array<AccountManual> = [];
  theme = CommonUtils.getCompany().toLocaleLowerCase();
  selectedYear = CommonUtils.getYear();
  loading = true;


  constructor(public service: AccountManualService, public authService: AuthService,
              public eventService: EventService<string>) {
    super();
  }

  ngOnInit() {
    this.service.getAccountManuals().subscribe((data) => {
      this.loading = false;
      this.accountManuals = data;
    });
  }

  goClick() {
    localStorage.setItem(Constants.EDIT_MODE_FLAG, 'true');
    localStorage.setItem(Constants.EDIT_YEAR, this.selectedYear);
    this.eventService.emit('true');
    this.eventService.emitYear(this.selectedYear);
  }

  statusClass() {
    const selectedStatus = (this.accountManuals && this.accountManuals.length === 0) ? '' : this.accountManuals.filter((m) => m.year === this.selectedYear)[0].status;
    return selectedStatus === Constants.LOCKED_STATUS ? ' disabled' : '';
  }
}
