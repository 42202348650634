import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorHandlingService } from '@app/services/error-handling.service';
import { AccountDetails } from '@app/model/account.details.model';
import { Constants } from '@app/utils/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class SearchAccountsService {

  constructor(private http: HttpClient, private errorHandlingService: ErrorHandlingService) {
  }

  loading: boolean;

  public searchAccounts(searchText: string, year: string): Observable<Array<AccountDetails>> {
    const url = Constants.SEARCH_ACCOUNTS_URL
    .replace('{searchText}', searchText)
    .replace('{year}', year);

    return this.http.get<Array<AccountDetails>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
}
