import {Component, EventEmitter, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@app/services/auth.service';
import {AuthConstants} from '@app/utils/constants/auth-constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';
import {ConfigResult} from '@app/utils/common/configResult';
import {AccessTokenResponse} from '@app/utils/common/accessTokenResponse';
import {Constants} from '@app/utils/constants/constants';

import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  tokenRefreshId: number = null;

  constructor(private router: Router, private authService: AuthService, private httpClient: HttpClient
  ) {
    if (location.href.split('?').length > 1) {
      const code = location.href.split('?')[1].split('&')[0].replace('code=', '');
      localStorage.removeItem(Constants.EDIT_MODE_FLAG);
      this.authService.requestIdTokenFromCode(code).subscribe((response: AccessTokenResponse) => {
        localStorage.setItem(AuthConstants.ACCESS_TOKEN_RESPONSE, JSON.stringify(response));
        this.tokenRefreshId = window.setInterval(() => {
          this.authService.tokenResponse = JSON.parse(localStorage.getItem(AuthConstants.ACCESS_TOKEN_RESPONSE));
          this.authService.refreshToken(this.authService.tokenResponse.refresh_token)
            .subscribe((res: AccessTokenResponse) => {
              localStorage.setItem(AuthConstants.ACCESS_TOKEN_RESPONSE, JSON.stringify(res));
            });
        }, AuthConstants.ACCESS_TOKEN_REFRESH_INTERVAL);

          const httpOptions = {
            headers: new HttpHeaders({
              Authorization: `Bearer ${response.access_token}`
            })
          };
          this.httpClient.get(environment.user_info,httpOptions).subscribe(result => {
            this.authService.user = result;
            localStorage.setItem(AuthConstants.USER, JSON.stringify(result));
            if (window.location.pathname === '/') {
              this.router.navigate(['/home']);
            }
          });


      });
    } else {
      if (this.authService.tokenResponse) {
        const user = jwt_decode(this.authService.tokenResponse.id_token);
        const utcSeconds = user.exp;
        const expDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
        expDate.setUTCSeconds(utcSeconds - 5 * 60);
        const now = new Date();
        if (now >= expDate) {
          localStorage.clear();
        }
      }
      this.authService.user = JSON.parse(localStorage.getItem(AuthConstants.USER));
      this.httpClient.get(environment.stsServer).subscribe((value: ConfigResult) => {
        localStorage.setItem(AuthConstants.STS_SERVER, JSON.stringify(value));
        if (localStorage.getItem(AuthConstants.ACCESS_TOKEN_RESPONSE) === null) {
          location.href = this.authService.authorizationUrl();
        } else {
          this.authService.tokenResponse = JSON.parse(localStorage.getItem(AuthConstants.ACCESS_TOKEN_RESPONSE));

          if (this.tokenRefreshId !== null) {
            clearInterval(this.tokenRefreshId);
          }
          this.tokenRefreshId = window.setInterval(() => {
            this.authService.tokenResponse = JSON.parse(localStorage.getItem(AuthConstants.ACCESS_TOKEN_RESPONSE));
            this.authService.refreshToken(this.authService.tokenResponse.refresh_token)
              .subscribe((response: AccessTokenResponse) => {
                localStorage.setItem(AuthConstants.ACCESS_TOKEN_RESPONSE, JSON.stringify(response));
              });
          }, AuthConstants.ACCESS_TOKEN_REFRESH_INTERVAL);
          if (window.location.pathname === '/') {
            this.router.navigate(['/home']);
          }

        }
      });
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
