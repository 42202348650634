import {Component, Input, OnInit} from '@angular/core';
import {Account} from '@app/model/account.model';
import {AccountDetailsComponent} from '@app/components/account-details/account-details.component';
import {MzModalService} from 'ngx-materialize';
import {AuthService} from '@app/services/auth.service';
import {Router} from '@angular/router';
import {Constants} from "@app/utils/constants/constants";
import {EditLineItemComponent} from '@app/components/page-definitions/edit/edit-line-item/edit-line-item.component';
import {EventService} from "@app/services/event.service";
import {Page} from "@app/model/page.model";

@Component({
  selector: 'app-account-row',
  templateUrl: './account-row.component.html'
})
export class AccountRowComponent implements OnInit {
  @Input() accountRow: Array<Account>;
  @Input() index: number;
  @Input() pageNumber: string;
  @Input() category: string;
  @Input() pageDefinition: Page;

  constructor(private modalService: MzModalService, public authService: AuthService, private router: Router,
              private eventService: EventService<string>) {
  }

  ngOnInit() {
  }

  renderAccountName(accountRow) {
    return this.renderAccountNameArray(accountRow).join(' / ');
  }

  renderAccountNameArray(accountRow) {
    return Array.from(new Set(accountRow.map(account => account.accountName)));
  }

  renderSlash(index) {
    return index === 0 ? '' : ' / ';
  }

  isDraft(): boolean {
    return this.accountRow.map((row) => row.status).includes(Constants.DRAFT_STATUS)
      || this.accountRow.map((row) => row.status).includes(Constants.REMOVE_STATUS);
  }

  isQueued(): boolean {
    return this.accountRow.map((row) => row.status).includes(Constants.QUEUED_STATUS);
  }

  isDraftOrQueued(): boolean {
    return this.accountRow.map((row) => row.status).includes(Constants.DRAFT_STATUS)
      || this.accountRow.map((row) => row.status).includes(Constants.REMOVE_STATUS)
      || this.accountRow.map((row) => row.status).includes(Constants.QUEUED_STATUS);
  }

  isRemoved(account): boolean {
    return (this.authService.getEditModeFlag() && (account.status === 'R' ||
      (account.status === 'Q' && account.queueAction === 'Remove')));
  }

  redirectToAccountDetails(accountId, detailId, accountNumber) {
    if (this.authService.getEditModeFlag()) {
      this.router.navigate([`admin/edit/account-details/${accountId}/${accountNumber}/${detailId}`]);
    } else {
      this.modalService.open(AccountDetailsComponent, {accountId, detailId});
    }
  }

  editAccounts(lineNo, pageNo, columnNumber) {
    this.modalService.open(EditLineItemComponent, {lineNo, pageNo, columnNumber}).onDestroy(() => {
      this.eventService.emitReload('');
    });
  }

  addAccounts(lineNo, pageNo, columnNumber) {
    this.modalService.open(EditLineItemComponent, {lineNo, pageNo, columnNumber}).onDestroy(() => {
      this.eventService.emitReload('');
    });
  }

  getIndex(index): string {
    if (this.authService.getEditModeFlag()) {
      return index + 1;
    }
    return this.pageDefinition.categories[this.category === '1' ? 1 : 0]
      .accounts[index][0].lineNo;
  }
}
