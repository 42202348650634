import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {PublishService} from '@app/services/publish.service';
import {CommonUtils} from '@app/utils/common/common-utils';
import {Constants} from '@app/utils/constants/constants';
import {Router} from '@angular/router';
import {EventService} from '@app/services/event.service';
import {Publish} from '@app/model/publish.model';
import {Summary} from '@app/model/summary.model';
import {throwError} from 'rxjs';
import {DocumentModel} from "@app/model/document.model";

@Component({
  selector: 'app-publish',
  templateUrl: './publish-container.component.html'
})
export class PublishContainerComponent extends BaseComponent implements OnInit, OnDestroy {

  loading = true;
  revertClass = 'disabled';
  queueClass = 'disabled';
  publishClass = 'disabled';
  selectedRecords: Array<Summary> = [];
  allSelected = false;
  rows = 0;

  constructor(private publishService: PublishService, private eventService: EventService<any>, public router: Router,
              private docService: EventService<DocumentModel>) {
    super();
  }

  ngOnInit() {
    this.reloadData();
    this.docService.emit(new DocumentModel('', '', '', '', '', 'Publish'));
    this.eventService.getRevertEventListener().subscribe((value: Publish) => {
      console.log(value);
      this.selectedRecords = [];
      this.selectedRecords.push(new Summary(value.id, value.company, value.year, value.pageNumber, value.summaryDefType));
      this.revertAll();
    });
    this.eventService.getQueueEventListener().subscribe((value: Publish) => {
      this.selectedRecords = [];
      this.selectedRecords.push(new Summary(value.id, value.company, value.year, value.pageNumber, value.summaryDefType));
      this.queueAll();
    });
    this.eventService.getPublishEventListener().subscribe((value: Publish) => {
      this.selectedRecords = [];
      this.selectedRecords.push(new Summary(value.id, value.company, value.year, value.pageNumber, value.summaryDefType));
      this.publishAll();
    });
  }

  reloadData() {
    localStorage.removeItem(Constants.PUBLISH_DATA);
    this.publishService.getSummaryForPublish(CommonUtils.getYear()).subscribe((data) => {
      this.rows = data.length;
      localStorage.setItem(Constants.PUBLISH_DATA, JSON.stringify(data));
      this.loading = false;
    });
    this.revertClass = 'disabled';
    this.queueClass = 'disabled';
    this.publishClass = 'disabled';
    this.allSelected = false;
    // subscribe event for selection change
    this.eventService.getPublishDataEvent().subscribe((value: Array<Publish>) => {
      this.rows = value.length;
      this.resetClasses(value);
      this.setSummaryPayload(value);
    });
  }

  setSummaryPayload(data: Array<Publish>) {
    this.selectedRecords = [];
    data.forEach(value => {
      if (value.selected === 'Y') {
        this.selectedRecords.push(new Summary(value.id, value.company, value.year, value.pageNumber, value.summaryDefType));
      }
    });
  }

  resetClasses(data: Array<Publish>) {
    this.revertClass = data.filter((row: Publish) => row.selected === 'Y').length > 0 ? '' : 'disabled';
    const statuses = [...new Set(data.filter((row: Publish) => row.selected === 'Y').map((row: Publish) => row.status))];
    // tslint:disable-next-line:max-line-length
    this.queueClass = (statuses.length > 0 && statuses.length === statuses.filter((value) => value === Constants.DRAFT_STATUS || value === Constants.REMOVE_STATUS).length) ? '' : 'disabled';
    this.publishClass = (statuses.length === 1 && (statuses[0] === Constants.QUEUED_STATUS)) ? '' : 'disabled';
  }

  ngOnDestroy(): void {
    localStorage.removeItem(Constants.PUBLISH_DATA);
  }

  private onPublishError(err) {
    return throwError('An error occurred.');
  }

  revertAll() {
    this.loading = true;
    this.publishService.revert(this.selectedRecords, (err) => this.onPublishError(err)).subscribe((data) => {
      this.reloadData();
    });
  }

  queueAll() {
    this.loading = true;
    this.publishService.queue(this.selectedRecords, (err) => this.onPublishError(err)).subscribe((data) => {
      this.reloadData();
    });
  }

  publishAll() {
    this.loading = true;
    this.publishService.publish(this.selectedRecords, (err) => this.onPublishError(err)).subscribe((data) => {
      this.reloadData();
    });
  }

  selectAll(value) {
    this.allSelected = value;
    this.eventService.selectAllRows(value);
  }
}
