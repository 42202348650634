import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonUtils} from '@app/utils/common/common-utils';
import {MzBaseModal} from 'ngx-materialize';
import {Category} from '@app/model/category.model';
import {AdminService} from '@app/services/admin.service';
import {AccountsService} from '@app/services/accounts.service';
import {ActivatedRoute} from '@angular/router';
import {EventService} from '@app/services/event.service';
import {DocumentModel} from '@app/model/document.model';
import {Account} from '@app/model/account.model';
import {Constants} from '@app/utils/constants/constants';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from '@app/services/auth.service';

@Component({
  selector: 'app-edit-line-item',
  templateUrl: './edit-line-item.component.html'
})
export class EditLineItemComponent extends MzBaseModal implements OnInit, OnDestroy {

  accounts = new Array<Account>();
  allAccounts: Array<{ detailId: string, accountNumber: string, accountName: string, page: string }>
    = new Array<{ detailId: string, accountNumber: string, accountName: string, page: string }>();
  @Input() lineNo;
  @Input() pageNo;
  @Input() columnNumber;
  categories: Array<Category>;
  loading = true;
  isNewAccount = false;
  theme = CommonUtils.getCompany().toLocaleLowerCase();
  lines: Array<number> = [];
  document: DocumentModel;
  saveSubscription: Subscription;
  revertSubscription: Subscription;
  editSubscription: Subscription;
  queueSubscription: Subscription;
  // TODO
  deleteSubscription: Subscription;


  private cancelClickedSource = new Subject<void>();
  cancelClicked = this.cancelClickedSource.asObservable();

  constructor(public adminService: AdminService, public accountsService: AccountsService,
              public route: ActivatedRoute, public authService: AuthService, public docService: EventService<DocumentModel>) {
    super();
  }

  ngOnInit() {
    this.initAdminEventHandlers();
    this.categories = CommonUtils.getPageCategories(this.pageNo);
    this.reloadAccounts(undefined);
    this.editSubscription = this.docService.getEditEvent().subscribe((doc) => {
      this.document = doc;
    });
  }

  // Avoid duplicate call from UI
  ngOnDestroy(): void {
    this.saveSubscription.unsubscribe();
    this.revertSubscription.unsubscribe();
    this.queueSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.editSubscription.unsubscribe();
  }

  reloadAccounts(expectedStatus?) {
    this.accountsService.getAccountsForEditLineItem(this.pageNo, this.lineNo,
      this.columnNumber, CommonUtils.getYear())
      .subscribe(data => {
        if (data.length === 0 || expectedStatus === undefined ||
          expectedStatus.split(',').includes(data[0].status)) {
          this.accounts = data;
          if (data.length === 0) {
            this.isNewAccount = true;
            this.addAccount();
          } else {
            this.isNewAccount = false;
            for (const account of this.accounts) {
              const doc = DocumentModel.initFromAccount(account);
              this.docService.emit(doc);
              if (account.status === Constants.PUBLISHED_STATUS && !account.publishedId) {
                account.publishedId = account.id;
                account.id = null;
              }
              if (account.detailId) {
                account.definition = 'existing';
              }
            }
          }
          this.accountsService.getAllAccounts(CommonUtils.getYear()).subscribe((allAccounts) => {
            this.allAccounts = allAccounts;
            this.loading = false;
            // this.accountsService.getLines(this.pageNo, this.columnNumber, CommonUtils.getYear())
            //   .subscribe((lines) => {
            //     this.lines = lines;
            //     this.loading = false;
            //   });
          });
        } else {
          setTimeout(() => {
            this.reloadAccounts(expectedStatus);
          }, 1000);
        }
      });
  }

  queueAccounts() {
    this.loading = true;
    this.docService.emit(undefined);
    const idList = this.getAccountsIds();
    this.accountsService.queueAccounts(idList).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.reloadAccounts(Constants.QUEUED_STATUS);
      }
    });
  }

  revertAccounts() {
    this.loading = true;
    this.docService.emit(undefined);
    const idList = this.getAccountsIds();
    this.accountsService.revertAccounts(idList).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.reloadAccounts(Constants.PUBLISHED_STATUS);
      }
    });
  }

  addAccount() {
    const account = new Account(null, '', '', parseInt(this.pageNo, 10), this.lineNo.toString(), this.lineNo.toString(),
      CommonUtils.getCompany(), this.categories[0].name, '', 'Y', CommonUtils.getYear(), this.columnNumber, Constants.NEW_STATUS, '', '', '', new Date().getTime().toString(), this.authService.getUser().userId);
    this.accounts.push(account);
    account.definition = 'new';
  }

  removeAccount(account: Account) {
    if (account.status === Constants.NEW_STATUS) {
      const index = this.accounts.indexOf(account);
      this.accounts.splice(index, 1);
    }
    account.status = Constants.REMOVE_STATUS;
  }

  isRemovedStatus(account) {
    return (account.status === Constants.REMOVE_STATUS
      || (account.status === Constants.QUEUED_STATUS && account.queueAction === Constants.QUEUE_ACTION_REMOVE));
  }

  isAddAllowed() {
    return this.accounts.filter(account => {
      return account.status !== Constants.REMOVE_STATUS;
    }).length < (this.isNewAccount ? 1 : 2);
  }

  private getAccountsIds() {
    const idList = new Array<{ id: string, queue_action: string }>();
    this.accounts.forEach(account => {
        if (account.publishedId !== null) {
          idList.push({
            id: account.id, queue_action:
              (account.status === Constants.REMOVE_STATUS ?
                Constants.QUEUE_ACTION_REMOVE : Constants.QUEUE_ACTION_QUEUE)
          });
        }
      }
    );
    return idList;
  }

  saveAccounts() {
    this.loading = true;
    this.accountsService.saveAccounts(this.accounts).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.reloadAccounts(Constants.DRAFT_REMOVE_STATUS);
      }
    });
  }

  private initAdminEventHandlers() {
    this.deleteSubscription = this.adminService.deleteClicked.subscribe(() => alert('delete!'));
    this.revertSubscription = this.adminService.revertClicked.subscribe(() => this.revertAccounts());
    this.saveSubscription = this.adminService.saveAsDraftClicked.subscribe(() => this.saveAccounts());
    this.queueSubscription = this.adminService.queueClicked.subscribe(() => this.queueAccounts());
  }

  reloadLines(column: string) {
    this.accountsService.getLines(this.pageNo, column, CommonUtils.getYear())
      .subscribe((lines) => {
        this.lines = lines;
      });
  }

  selectDefinition(account: Account) {
    console.log(account);
  }

  selectAccount(account: Account) {
    console.log(account);
  }
}
