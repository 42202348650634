let redirectUrl: string =  window.location.hostname;
if (redirectUrl.startsWith('www.')) {redirectUrl = redirectUrl.replace('www.', ''); }

export const environment = {
 awsLambdaBaseUrl: 'https://wlismjvv09.execute-api.us-east-2.amazonaws.com/dev/',
  awsAccountsLambdaBaseUrl: 'https://wlismjvv09.execute-api.us-east-2.amazonaws.com/dev/',
  awsPublishLambdaBaseUrl: 'https://wlismjvv09.execute-api.us-east-2.amazonaws.com/dev/',
  stsServer: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/.well-known/openid-configuration',
  authorization_endpoint: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/authorize',
  access_token: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/access_token',
  authorization: 'Basic Uk1ELUZBOjVjYWMxNjQwLWRlOTEtNDg1OS1iYWVhLWZiMzk2N2M1NmEwYg==',
  logoutUrl: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/connect/endSession',
  redirectUrl: 'https://' + redirectUrl + '/',
  clientId: 'RMD-FA',
  grantType: 'authorization_code',
  grantTypeRefresh: 'refresh_token',
  production: false,
  stage: 'dev',
  queryParams: 'service=RMD-FA',
  user_info: 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily/userinfo'
};
