import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '@app/services/auth.service';
import {EventService} from '@app/services/event.service';
import {AccessTokenResponse} from '@app/utils/common/accessTokenResponse';
import {AuthConstants} from '@app/utils/constants/auth-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  public route: { routeConfig: { path: string } } = {routeConfig: {path: ''}};
  private state: RouterStateSnapshot;

  constructor(private router: Router, private authService: AuthService,
              private eventService: EventService<boolean>
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.route = {
      routeConfig: {
        path: route.routeConfig.path
      }
    };
    this.state = state;
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    return this.authService.getIsAuthorized().pipe(
      map((isAuthorized: boolean) => {
        if (!isAuthorized) {
          if (!(location.href.split('?').length > 1)) {
            location.href = this.authService.authorizationUrl();
          } else {
            const code = location.href.split('?')[1].split('&')[0].replace('code=', '');
            this.authService.requestIdTokenFromCode(code).subscribe((response: AccessTokenResponse) => {
              localStorage.setItem(AuthConstants.ACCESS_TOKEN_RESPONSE, JSON.stringify(response));
              this.router.navigate(['/home']);
            });
          }
          return false;
        } else {
          if (this.route.routeConfig.path === 'admin') {
            if (this.authService.getEditModeFlag()
              || (this.state.url === '/admin/settings' && this.authService.isUserAdmin())) {
              return true;
            } else {
              this.router.navigate(['/home']);
            }
          }
          return true;
        }
      })
    );
  }
}
