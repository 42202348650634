import {Component, Input, OnInit} from '@angular/core';
import {Example} from '@app/model/example.model';
import {ShowdownConverter} from 'ngx-showdown';
import {EventService} from '@app/services/event.service';
import {ConfigUtils} from '@app/utils/common/Config-Util';

@Component({
  selector: 'app-edit-example',
  templateUrl: './edit-example.component.html'
})
export class EditExampleComponent implements OnInit {

  config = ConfigUtils.getEditorConfig();
  @Input() example: Example = new Example('', '', '');

  constructor(public showdownConverter: ShowdownConverter, public eventService: EventService<Example>) {
  }

  ngOnInit() {
    this.example.debitHtml = this.showdownConverter.makeHtml(this.example.debit);
    this.example.creditHtml = this.showdownConverter.makeHtml(this.example.credit);
  }

  changeDebit($event) {
    this.example.debit = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  changeCredit($event) {
    this.example.credit = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  removeExample(example: Example) {
    this.eventService.emitExample(example);
  }
}
