import {EventEmitter, Injectable} from '@angular/core';
import {DocumentModel} from '@app/model/document.model';
import {Example} from '@app/model/example.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService<T> {
  loadingEvent: EventEmitter<T> = new EventEmitter();
  editEvent: EventEmitter<T> = new EventEmitter();
  exampleEvent: EventEmitter<Example> = new EventEmitter();
  genericEvent: EventEmitter<T> = new EventEmitter();
  modalEvent: EventEmitter<{ open: boolean, isSessionRefresh: boolean, msToExpire: number }> =
    new EventEmitter<{ open: boolean, isSessionRefresh: boolean, msToExpire: number }>();
  logoutEvent: EventEmitter<{ logout: boolean }> = new EventEmitter<{ logout: boolean }>();
  publishDataEvent: EventEmitter<any> = new EventEmitter<any>();
  publishEventListener: EventEmitter<any> = new EventEmitter<any>();
  revertEventListener: EventEmitter<any> = new EventEmitter<any>();
  queueEventListener: EventEmitter<any> = new EventEmitter<any>();
  selectAllPublishRowsEventListener: EventEmitter<any> = new EventEmitter<any>();
  yearEvent: EventEmitter<string> = new EventEmitter<string>();
  allAccountDetailsEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  emit(value: T) {
    if (value instanceof DocumentModel) {
      this.editEvent.emit(value);
    } else if (typeof (value) === 'string') {
      this.loadingEvent.emit(value);
    } else if (value instanceof Example) {
      this.exampleEvent.emit(value);
    } else {
      this.genericEvent.emit(value);
    }
  }

  emitYear(value) {
    this.yearEvent.emit(value);
  }

  emitAllAccountDetails(value) {
    this.allAccountDetailsEvent.emit(value);
  }
  emitReload(value) {
    this.loadingEvent.emit(value);
  }

  emitExample(value: Example) {
    this.exampleEvent.emit(value);
  }

  emitModalEvent(value: { open: boolean, isSessionRefresh: boolean, msToExpire: number }) {
    this.modalEvent.emit(value);
  }

  emitLogoutEvent(value: { logout: boolean }) {
    this.logoutEvent.emit(value);
  }

  emitPublishDataEvent(value: any) {
    this.publishDataEvent.emit(value);
  }

  emitPublishEventListener(value: any) {
    this.publishEventListener.emit(value);
  }

  emitQueueEventListener(value: any) {
    this.queueEventListener.emit(value);
  }

  emitRevertEventListener(value: any) {
    this.revertEventListener.emit(value);
  }

  selectAllRows(value: any) {
    this.selectAllPublishRowsEventListener.emit(value);
  }

  getLoadingEvent() {
    return this.loadingEvent;
  }

  getEditEvent() {
    return this.editEvent;
  }

  getExampleEvent() {
    return this.exampleEvent;
  }

  getModalEvent(): Observable<{ open: boolean, isSessionRefresh: boolean, msToExpire: number }> {
    return this.modalEvent;
  }

  getLogoutEvent(): Observable<{ logout: boolean }> {
    return this.logoutEvent;
  }

  getPublishDataEvent(): Observable<any> {
    return this.publishDataEvent;
  }

  getPublishEventListener(): Observable<any> {
    return this.publishEventListener;
  }

  getQueueEventListener(): Observable<any> {
    return this.queueEventListener;
  }

  getRevertEventListener(): Observable<any> {
    return this.revertEventListener;
  }

  getAllRowsEventListener(): Observable<any> {
    return this.selectAllPublishRowsEventListener;
  }

  getGenericEvent() {
    return this.genericEvent;
  }
  getYear() {
    return this.yearEvent;
  }
  getAllAccountDetails(){
    return this.allAccountDetailsEvent;
  }
}
