import {Page} from '@app/model/page.model';
import {Constants} from '@app/utils/constants/constants';
import {Company} from '@app/utils/company';
import {Category} from '@app/model/category.model';

export class CommonUtils {

  constructor() {
  }

  static getCompany(): string {
    if (localStorage.getItem(Constants.COMPANY) === undefined || localStorage.getItem(Constants.COMPANY) === null) {

      const url = window.location.hostname;
      if (url.includes('lexus')) {
        localStorage.setItem(Constants.COMPANY, Company[Company.Lexus]);
      } else {
        localStorage.setItem(Constants.COMPANY, Company[Company.Toyota]);
      }
    }
    return localStorage.getItem(Constants.COMPANY);
  }

  static getYear(): string {
    if (localStorage.getItem(Constants.EDIT_MODE_FLAG) === 'true') {
      return localStorage.getItem(Constants.EDIT_YEAR);
    } else {
      return localStorage.getItem(Constants.ACCOUNT_MANUAL_YEAR);
    }
  }

  static getPage(pageNo): Page {
    const pageObj = JSON.parse(localStorage.getItem(Constants.PAGE_DEFINITIONS))[pageNo];

    pageObj.categories.map(category => {
      delete category.accounts;
    });

    return Page.fromData(pageObj);
  }

  static getPageWithAccounts(pageNo): Page {
    const pageObj = JSON.parse(localStorage.getItem(Constants.PAGE_DEFINITIONS))[pageNo];

    return Page.fromData(pageObj);
  }

  static getPageName(pageNo): string {
    const pageDefinition: Page = JSON.parse(localStorage.getItem(Constants.PAGE_DEFINITIONS))[pageNo];
    return pageDefinition.title;
  }

  static getPageCategories(pageNo): Array<Category> {
    const pageObj = JSON.parse(localStorage.getItem(Constants.PAGE_DEFINITIONS))[pageNo];

    pageObj.categories.map(category => {
      delete category.accounts;
    });

    const categories = pageObj.categories.filter(((value, index) => value.name !== ''))
      .map((categoryObj) => Category.fromData(categoryObj));

    return categories;
  }
}
