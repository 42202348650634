import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ViewAccountsComponent} from './components/page-definitions/view-accounts/view-accounts.component';
import {AppComponent} from '@app/app.component';
import {HeaderComponent} from '@app/components/home/header/header.component';
import {HomeComponent} from '@app/components/home/home.component';
import {AccountDetailsComponent} from '@app/components/account-details/account-details.component';
import {StoreModule} from '@ngrx/store';
import {reducers, metaReducers} from '@app/reducers';
import {OverviewComponent} from '@app/components/overview/overview.component';
import {SharedModule} from '@app/shared/shared.module';
import {AppRoutingModule} from '@app/app-routing.module';
import {BaseComponent} from '@app/components/base/base.component';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import {PageDefinitionsComponent} from '@app/components/page-definitions/page-definitions.component';
import {ApiInterceptorService} from '@app/services/api-interceptor.service';
import {AmTabsComponent} from '@app/components/home/am-tabs/am-tabs.component';
import {RouterModule} from '@angular/router';
import {ExampleComponent} from './components/account-details/example/example.component';
import {
  MzButtonModule,
  MzCheckboxModule,
  MzCollapsibleModule,
  MzModalModule,
  MzSelectModule,
  MzTabModule,
  MzTooltipModule
} from 'ngx-materialize';
import {AmSearchComponent} from './components/am-search/am-search.component';
import {AccountRowComponent} from '@app/components/page-definitions/account-row/account-row.component';
import {SearchItemComponent} from './components/am-search/search-item/search-item.component';
import {TabComponent} from '@app/components/home/am-tabs/tab/tab.component';
import {TabItemComponent} from '@app/components/home/am-tabs/tab/tab-item/tab-item.component';
import {AutofocusDirective} from './directives/autofocus.directive';
import {FormsModule} from '@angular/forms';


import {MzDropdownModule} from 'ngx-materialize';
import {NgxMdModule} from 'ngx-md';
import {NgSelect2Module} from 'ng-select2';
import {EditComponent} from './components/home/header/edit/edit.component';
import {EditPageDefinitionsComponent} from './components/page-definitions/edit/edit-page-definitions/edit-page-definitions.component';
import {AdminComponent} from './components/admin/admin.component';
import {EditOverviewComponent} from './components/overview/edit-overview.component';
import {AdminToolbarComponent} from './components/admin/admin-toolbar/admin-toolbar.component';
import {CKEditorModule} from 'ckeditor4-angular';
import {EditAccountDetailsComponent} from './components/account-details/edit/edit-account-details/edit-account-details.component';
import {ShowdownModule} from 'ngx-showdown';
import {EditExampleComponent} from './components/account-details/edit/edit-example/edit-example.component';
import {AdminHeaderComponent} from './components/admin/admin-header/admin-header.component';
import {CheckboxComponent} from './components/common/checkbox/checkbox.component';
import {PublishModalComponent} from './components/publish-modal/publish-modal.component';
import {CountdownModule} from 'ngx-countdown';
import {IE9StateComponent} from './components/ie9-state/ie9-state.component';
import {CopyComponent} from './components/home/header/copy/copy.component';
import {SettingsComponent} from './components/home/header/settings/settings.component';
import {ToggleComponent} from './components/home/header/toggle/toggle.component';
import {EditLineItemComponent} from './components/page-definitions/edit/edit-line-item/edit-line-item.component';
import {EditContainerComponent} from './components/admin/edit-container/edit-container.component';
import {PublishContainerComponent} from './components/publish/publish-container/publish-container.component';
import {PagerComponent} from './components/publish/pager/pager.component';
import {TabMenuComponent} from './components/home/am-tabs/tab/tab-menu/tab-menu.component';
import {PublishDataComponent} from './components/publish/publish-data/publish-data.component';
import { AppendixListComponent } from './components/appendix/appendix-list/appendix-list.component';
import { EditAppendixComponent } from './components/appendix/edit-appendix/edit-appendix.component';
import { AppendixComponent } from './components/appendix/appendix/appendix.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AmPdfExportComponent } from './components/am-pdf-export/am-pdf-export.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AccountDetailsComponent,
    ViewAccountsComponent,
    OverviewComponent,
    AmSearchComponent,
    BaseComponent,
    PageDefinitionsComponent,
    AmTabsComponent,
    ExampleComponent,
    AmSearchComponent,
    AccountRowComponent,
    SearchItemComponent,
    TabComponent,
    TabItemComponent,
    AutofocusDirective,
    EditComponent,
    CopyComponent,
    EditPageDefinitionsComponent,
    AdminComponent,
    EditOverviewComponent,
    AdminToolbarComponent,
    AdminHeaderComponent,
    EditAccountDetailsComponent,
    EditExampleComponent,
    CheckboxComponent,
    PublishModalComponent,
    IE9StateComponent,
    PublishModalComponent,
    CopyComponent,
    SettingsComponent,
    ToggleComponent,
    EditLineItemComponent,
    EditContainerComponent,
    PublishContainerComponent,
    PagerComponent,
    TabMenuComponent,
    PublishDataComponent,
    AppendixListComponent,
    EditAppendixComponent,
    AppendixComponent,
    FileViewerComponent,
    ConfirmModalComponent,
    AmPdfExportComponent
  ],
  imports: [

    SharedModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    NgxMdModule.forRoot(),
    MzDropdownModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    AppRoutingModule,
    MzModalModule,
    MzButtonModule,
    FormsModule,
    MzTabModule,
    MzTooltipModule,
    MzSelectModule,
    CKEditorModule,
    ShowdownModule.forRoot({emoji: false, noHeaderId: true, flavor: 'github'}),
    CountdownModule,
    MzCollapsibleModule,
    MzCheckboxModule,
    NgSelect2Module,
    DragDropModule,
    PdfViewerModule
  ],
  exports: [
    TabComponent,
    TabItemComponent,
    ToggleComponent
  ],
  entryComponents: [OverviewComponent, ViewAccountsComponent,
    PageDefinitionsComponent, HomeComponent, AccountDetailsComponent, AdminComponent, EditComponent, CopyComponent,
    PublishModalComponent, SettingsComponent, ToggleComponent, EditLineItemComponent, AppendixComponent, ConfirmModalComponent, AmPdfExportComponent],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
