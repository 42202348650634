import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RouterLinkDirectiveStub } from '@app/shared/testing/router-link-directive-stub';
import {TooltipDirectiveStub} from '@app/shared/testing/tooltip-directive-stub';

@NgModule({
  declarations: [  RouterLinkDirectiveStub, TooltipDirectiveStub],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: []
})

export class SharedModule {
}
