import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {Page} from '@app/model/page.model';
import {Router} from '@angular/router';
import {Constants} from '@app/utils/constants/constants';

@Component({
  selector: 'app-am-tabs',
  templateUrl: './am-tabs.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AmTabsComponent extends BaseComponent implements OnInit {

  tabs: Array<Page>;

  constructor(public router: Router) {
    super();
  }

  ngOnInit() {
    this.tabs = JSON.parse(localStorage.getItem(Constants.PAGE_DEFINITIONS));
  }


  getPageLabel(tab) {
    switch (tab.pageNumber) {
      case '0': {
        return tab.title;
      }
      case 0: {
        return tab.title;
      }
      case '999': {
        return tab.title;
      }
      default: {
        return 'Page ' + tab.pageNumber;
      }
    }
  }

  getPageLink(tab) {
    switch (tab.pageNumber) {
      case '0': {
        return 'overview';
      }
      case 0: {
        return 'overview';
      }
      default: {
        return 'page/' + tab.pageNumber;
      }
    }
  }
}
