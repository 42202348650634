import {environment} from '@env';

export class AuthConstants {
  public static SCOPE = 'openid RMD';
  public static RESPONSE_TYPE = 'code';
  public static RMD_ADMIN = 'RMD_Admin';
  public static RMD_REPORT_ADMIN = 'RMD_Report_Admin';
  public static STS_SERVER = 'STS_SERVER';
  public static ACCESS_TOKEN_RESPONSE = 'ACCESS_TOKEN_RESPONSE';
  public static USER = 'USER';
  public static ACCESS_TOKEN_REFRESH_INTERVAL = 3000000; // 50 minutes

}
