import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Account } from '@app/model/account.model';
import { ErrorHandlingService } from '@app/services/error-handling.service';
import { Constants } from '@app/utils/constants/constants';
import { Response } from '@app/model/response.model';
import {AccountDetails} from '@app/model/account.details.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  loading: boolean;
  constructor(private http: HttpClient, private errorHandlingService: ErrorHandlingService) {
  }

  public getAccountsForEditLineItem(pageNumber: string, lineNumber: string, columnNumber: string,
                                    year: string): Observable<Array<Account>> {
    const url =
      Constants.ACCOUNTS_FOR_EDIT_LINE_ITEM_URL
        .replace('{year}', year)
        .replace('{pageNumber}', pageNumber)
        .replace('{lineNumber}', lineNumber)
        .replace('{columnNumber}', columnNumber);

    return this.http.get<Array<Account>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public saveAccounts(accounts: Array<Account>): Observable<Response> {
    const body = {
      accounts
    };

    return this.http.post<Response>(Constants.SAVE_ACCOUNTS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public queueAccounts(idList: Array<{ id: string, queue_action: string }>): Observable<Response> {
    const body = {
      idList
    };

    return this.http.post<Response>(Constants.QUEUE_ACCOUNTS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public revertAccounts(idList: Array<{ id: string, queue_action: string }>): Observable<Response> {
    const body = {
      idList
    };

    return this.http.post<Response>(Constants.REVERT_ACCOUNTS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getNewAccounts(): Observable<Array<{title: string, accounts: Array<Account>}>> {
    const url = Constants.GET_NEW_ACCOUNTS_URL;

    return this.http.get<Array<{title: string, accounts: Array<Account>}>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
  public getLines(page: string, column: string, year: string): Observable<Array<number>> {
    const url = Constants.GET_LINES_URL
      .replace('{page}', page)
      .replace('{column}', column)
      .replace('{year}', year);

    return this.http.get<Array<number>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
  public getAllAccounts(year: string):
    Observable<Array<{detailId: string, accountNumber: string, accountName: string, page: string}>> {
    const url = Constants.GET_ALL_ACCOUNTS
      .replace('{year}', year);

    return this.http.get<Array<{detailId: string, accountNumber: string, accountName: string, page: string}>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
}
