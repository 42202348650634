import { Component, Input, OnInit } from '@angular/core';
import { AccountDetailsComponent } from '@app/components/account-details/account-details.component';
import { MzModalService } from 'ngx-materialize';
import { BaseComponent } from '@app/components/base/base.component';
import { AccountDetails } from '@app/model/account.details.model';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss']
})
export class SearchItemComponent extends BaseComponent implements OnInit {
  @Input() account: AccountDetails;
  constructor(private modalService: MzModalService) {
    super();
  }

  ngOnInit() {
  }
  openAccountDetails(accountId, detailId) {
    this.modalService.open(AccountDetailsComponent, {accountId, detailId});
  }
}
