import {Component, Input, OnInit} from '@angular/core';
import {CommonUtils} from '@app/utils/common/common-utils';
import {MzBaseModal} from 'ngx-materialize';
import {ActionService} from '@app/services/action.service';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html'
})
export class ToggleComponent extends MzBaseModal implements OnInit {

  theme = CommonUtils.getCompany().toLocaleLowerCase();
  @Input() type: string;
  @Input() year: string;
  @Input() status: string;
  @Input() callback: (year: string) => {};
  constructor(public actionService: ActionService) {
    super();
  }

  ngOnInit() {
    console.log(this.year);
  }

  Archive() {

  }

  Activate() {

  }

  Lock() {

  }
}
