export class PublishConstants {
  public static PUBLISH_PREFIX = 'admin/publish/';
  public static APPENDIX_DELETE = 'admin/appendix/delete';
  public static PUBLISH_MANUAL_URL = PublishConstants.PUBLISH_PREFIX + 'manual';
  public static PUBLISH_OVERVIEW_URL = PublishConstants.PUBLISH_PREFIX + 'overview';
  public static PUBLISH_PAGE_URL = PublishConstants.PUBLISH_PREFIX + 'page';
}

export enum PublishType {
  Manual = 'manual',
  Overview = 'overview',
  Page = 'page',
  Appendix = 'appendix'
}
