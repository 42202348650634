export class Example {
  constructor(
    public exampleName: string,
    public debit: string,
    public credit: string
  ) {
  }
  public debitHtml: string;
  public creditHtml: string;
}
