import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ie9-state',
    templateUrl: './ie9-state.component.html'
})
export class IE9StateComponent implements OnInit {
    constructor() {
    }

    ngOnInit(): void {
    }
}
