import { Example } from '@app/model/example.model';

export class AccountDetails {
  constructor(
    public id: string,
    public detailId: string,
    public accountName: string,
    public accountNumber: string,
    public pageNo: string,
    public lineNo: string,
    public company: string,
    public category: string,
    public description: string,
    public comments: string,
    public examples: Array<Example>,
    public accountId?: string,
    public pageName?: string,
    public status?: string,
    public isNew?: string,
    public publishedId?: string,
    public editedDate?: string,
    public editedBy?: string
  ) {
  }
  public descriptionHtml: string;
  public commentsHtml: string;
  public static Empty() {
    return new AccountDetails('', '', '', '',
      '', '', '', '', '', '', new Array<Example>());
  }
}
