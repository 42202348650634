import { Account } from '@app/model/account.model';

export class Category {
    constructor(
      public name: string,
      public column: string,
      public accounts = new Array<Array<Account>>()
    ) {
    }

    static fromData(obj: any): Category {
      if (!obj) {
        return null;
      }

      const accounts =
        obj.accounts
          ? obj.accounts.map((objAccountList) => {
            const accountList = objAccountList.map((objAccount) => Account.fromData(objAccount));

            return accountList;
          })
          : new Array<Array<Account>>();


      const category =
        new Category(
          obj.name,
          obj.column,
          accounts
        );

      return category;
    }

    public hasQueuedContent(): boolean {
      if (this.accounts) {
        for (const accountList of this.accounts) {
          if (!accountList) {
            continue;
          }

          for (const account of accountList) {
            if (account.hasQueuedContent()) {
              return true;
            }
          }
        }
      }

      return false;
    }
}
