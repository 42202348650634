import { Directive, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tooltip]'
})
// tslint:disable-next-line:directive-class-suffix
export class TooltipDirectiveStub {
  @HostListener('click')
  onClick() {
  }
}
