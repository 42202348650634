import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '@app/services/admin.service';
import {BaseComponent} from '@app/components/base/base.component';
import {AccountDetailsService} from '@app/services/account-details.service';
import {ActivatedRoute} from '@angular/router';
import {AccountDetails} from '@app/model/account.details.model';
import {ShowdownConverter} from 'ngx-showdown';
import {Example} from '@app/model/example.model';
import {Observable, of, Subscription} from 'rxjs';
import {EventService} from '@app/services/event.service';
import {CommonUtils} from '@app/utils/common/common-utils';
import {DocumentModel} from '@app/model/document.model';
import {Constants} from '@app/utils/constants/constants';
import {AccountDetailsComponent} from '@app/components/account-details/account-details.component';
import {MzModalService} from 'ngx-materialize';
import {ConfigUtils} from '@app/utils/common/Config-Util';

@Component({
  selector: 'app-edit-account-details',
  templateUrl: './edit-account-details.component.html'
})
export class EditAccountDetailsComponent extends BaseComponent implements OnInit, OnDestroy {


  accountDetails: AccountDetails = AccountDetails.Empty();
  config = ConfigUtils.getEditorConfig();
  accountId: string;
  accountNumber: string;
  detailId: string;
  document: DocumentModel;

  examples: Observable<Array<Example>>;
  accounts: Array<Account> = [];
  loading = true;
  saveSubscription: Subscription;
  revertSubscription: Subscription;
  queueSubscription: Subscription;
  editSubscription: Subscription;
  exampleSubscription: Subscription;

  constructor(public adminService: AdminService, public accountDetailsService: AccountDetailsService,
              public route: ActivatedRoute, public showdownConverter: ShowdownConverter,
              public eventService: EventService<Example>,
              public docService: EventService<DocumentModel>,
              public modalService: MzModalService) {
    super();
  }

  ngOnInit() {
    this.initAdminEventHandlers();
    this.editSubscription = this.docService.getEditEvent().subscribe((doc) => {
      this.document = doc;
    });
    this.exampleSubscription = this.eventService.getExampleEvent()
      .subscribe((example) => {
        this.removeExample(example);
      });
    this.route.params.subscribe(params => {
      this.accountId = params.accountId;
      this.accountNumber = params.accountNumber;
      this.detailId = params.detailId;
      this.reloadAccountDetails(undefined);
    });

  }

  ngOnDestroy(): void {
    this.saveSubscription.unsubscribe();
    this.revertSubscription.unsubscribe();
    this.queueSubscription.unsubscribe();
    this.editSubscription.unsubscribe();
    this.exampleSubscription.unsubscribe();
  }

  reloadAccountDetails(expectedStatus?) {
    this.accountDetailsService.getAccountDetailsForEdit(this.accountNumber, this.detailId)
      .subscribe(data => {
        if (data.status === expectedStatus || expectedStatus === undefined) {
          this.accountDetails = data;
          const doc = DocumentModel.initFromAccountDetails(this.accountDetails, 'Page Definition');
          this.docService.emit(doc);
          this.examples = of(this.accountDetails.examples);
          this.accountDetails.descriptionHtml = this.showdownConverter.makeHtml(data.description);
          this.accountDetails.commentsHtml = this.showdownConverter.makeHtml(data.comments);
          if (this.accountDetails.publishedId == null) {
            this.accountDetails.publishedId = this.accountDetails.id;
            this.accountDetails.id = null;
          }
          this.accountDetailsService.getAccountsByDetailId(
            CommonUtils.getYear(),
            this.accountDetails.detailId).subscribe(acctsByDetailId => {
            this.accounts = acctsByDetailId;
            this.loading = false;
            this.docService.emitReload('');
          });
        } else {
          this.delay(1000).then(() => this.reloadAccountDetails(expectedStatus));
        }
      });
  }

  changeDescription($event) {
    this.accountDetails.description = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  changeComments($event) {
    this.accountDetails.comments = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  preview() {
    this.modalService.open(AccountDetailsComponent, {accountDetails: this.accountDetails});
  }

  addExample() {
    this.examples.subscribe(current => {
      current.push(new Example('', '', ''));
      this.accountDetails.examples = current;
    });
  }

  removeExample(example: Example) {
    if (this.examples) {
      this.examples.subscribe(current => {
        const index = current.indexOf(example);
        current.splice(index, 1);
        this.accountDetails.examples = current;
      });
    }
  }

  private initAdminEventHandlers() {
    this.revertSubscription = this.adminService.revertClicked.subscribe(() => this.revertAccountDetails());
    this.saveSubscription = this.adminService.saveAsDraftClicked.subscribe(() => this.saveAccountDetails());
    this.queueSubscription = this.adminService.queueClicked.subscribe(() => this.queueAccountDetails());
  }

  saveAccountDetails() {
    this.loading = true;
    this.accountDetailsService.saveAccountDetails(CommonUtils.getYear(),
      this.accountDetails).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.reloadAccountDetails('D');
      }
    });
  }

  queueAccountDetails() {
    this.loading = true;
    this.docService.emit(undefined);
    if (this.accountDetails.publishedId !== null) {
      this.accountDetailsService.queueAccountDetails(CommonUtils.getYear(),
        this.accountDetails.id, this.accountDetails.detailId).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.reloadAccountDetails('Q');
        }
      });
    }
  }

  revertAccountDetails() {
    this.loading = true;
    this.docService.emit(undefined);
    if (this.accountDetails.publishedId !== null) {
      this.accountDetailsService.revertAccountDetails(CommonUtils.getYear(),
        this.accountDetails.id, this.accountDetails.detailId).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.reloadAccountDetails('P');
        }
      });
    }
  }
}
