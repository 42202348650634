import {Component, Input, OnInit} from '@angular/core';
import {AccountDetails} from "@app/model/account.details.model";
import {CommonUtils} from "@app/utils/common/common-utils";
import {AccountDetailsService} from "@app/services/account-details.service";
import {BaseComponent} from "@app/components/base/base.component";

@Component({
  selector: 'app-am-pdf-export',
  templateUrl: './am-pdf-export.component.html'
})
export class AmPdfExportComponent extends BaseComponent implements OnInit {
  @Input() accountDetailsList: AccountDetails[];
  pageName: string;
  theme = CommonUtils.getCompany().toLocaleLowerCase();

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
