import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  private activationClickedSource = new Subject<string>();
  private lockToggleClickedSource = new Subject<{ year: string, status: string }>();
  private archiveClickedSource = new Subject<string>();
  activationClicked = this.activationClickedSource.asObservable();
  lockToggleClicked = this.lockToggleClickedSource.asObservable();
  archiveClicked = this.archiveClickedSource.asObservable();

  constructor() {
  }

  raiseActivationClicked(execute: boolean, year: string) {
    if (execute) {
      this.activationClickedSource.next(year);
    }
  }
  raiseLockToggleClicked(execute: boolean, data: { year: string, status: string }) {
    if (execute) {
      this.lockToggleClickedSource.next(data);
    }
  }
  raiseArchiveClicked(execute: boolean, year: string) {
    if (execute) {
      this.archiveClickedSource.next(year);
    }
  }
}
