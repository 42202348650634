export class User {
  constructor(
    public userId: string,
    public FIRST_NAME: string,
    public LAST_NAME: string,
    public email: string,
    public dealerNumber: string,
    public regionCode: string,
    public roles: string[],
    public spinId: string,
    public tmscorporateregioncode: string,
    public userType: string
  ) {
  }

  public static EMPTY = new User('', '',
    '', '', '', '', [], '', '', '');
  public userName = `${this.LAST_NAME}, ${this.FIRST_NAME}`;
}
