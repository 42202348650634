import {Constants} from '@app/utils/constants/constants';

export class Account {
  title?: any;
  public definition?: string;
  constructor(
    public id: string,
    public accountName: string,
    public accountNumber: string,
    public pageNo: number,
    public lineNo: string,
    public newLineNo: string,
    public company: string,
    public category: string,
    public detailId: string,
    public isNew: string,
    public year: string,
    public columnNumber: string,
    public status: string,
    public detailStatus: string,
    public publishedId: string,
    public pageName?: string,
    public editedDate?: string,
    public editedBy?: string,
    public queueAction?: string
  ) {
  }

  static fromData(obj: any): Account {
    if (!obj) {
      return null;
    }

    const account =
      new Account(
        obj.id,
        obj.accountName,
        obj.accountNumber,
        obj.pageNo,
        obj.lineNo,
        obj.newLineNo,
        obj.company,
        obj.category,
        obj.detailId,
        obj.isNew,
        obj.year,
        obj.columnNumber,
        obj.status,
        obj.detailStatus,
        obj.publishedId,
        obj.pageName,
        obj.editedDate,
        obj.editedBy,
        obj.queueAction
      );

    return account;
  }

  public hasQueuedContent(): boolean {
    return this.status === Constants.QUEUED_STATUS || this.detailStatus === Constants.QUEUED_STATUS;
  }
}
