import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {Page} from '@app/model/page.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Constants} from '@app/utils/constants/constants';
import {PageDefinitionService} from '@app/services/page-definition.service';
import {AuthService} from '@app/services/auth.service';
import {AdminService} from '@app/services/admin.service';
import {ShowdownConverter} from 'ngx-showdown';
import {CommonUtils} from '@app/utils/common/common-utils';
import {EventService} from '@app/services/event.service';
import {DocumentModel} from '@app/model/document.model';
import {ConfigUtils} from '@app/utils/common/Config-Util';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-edit-page-definitions',
  templateUrl: './edit-page-definitions.component.html'
})
export class EditPageDefinitionsComponent extends BaseComponent implements OnInit, OnDestroy {
  pageDefinition: Page;
  company: string;
  year: string;
  columnCount: number;
  id: string;
  descriptionHtml: string;
  loading = true;
  public categoryColumns = [{column: 1, name: 'one'}, {column: 2, name: 'two'}];
  config = ConfigUtils.getEditorConfig();
  saveSubscription: Subscription;
  revertSubscription: Subscription;
  queueSubscription: Subscription;

  constructor(public route: ActivatedRoute,
              public router: Router,
              public authService: AuthService,
              public service: PageDefinitionService,
              public adminService: AdminService,
              private showdownConverter: ShowdownConverter,
              private docService: EventService<DocumentModel>) {
    super();
  }

  ngOnInit() {
    this.hideSubHeader = false;
    this.company = localStorage.getItem(Constants.COMPANY);
    this.year = CommonUtils.getYear();
    this.initAdminEventHandlers();
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.reloadPage(undefined);
    });
  }

  ngOnDestroy(): void {
    this.saveSubscription.unsubscribe();
    this.revertSubscription.unsubscribe();
    this.queueSubscription.unsubscribe();
  }
  changeDescription($event) {
    this.pageDefinition.description = this.showdownConverter.makeMarkdown($event.editor.getData());
  }

  changeColumns(columnCount) {
    if (columnCount === '1') {
      this.pageDefinition.categories[1].name = '';
    }
  }

  private initAdminEventHandlers() {
    // this.adminService.deleteClicked.subscribe(() => alert('delete!'));
    this.revertSubscription = this.adminService.revertClicked.subscribe(() => this.revertPageDefinitions());
    this.saveSubscription = this.adminService.saveAsDraftClicked.subscribe(() => this.savePageDefinitions());
    this.queueSubscription = this.adminService.queueClicked.subscribe(() => this.queuePageDefinitions());
  }

  savePageDefinitions() {
    this.loading = true;
    this.service.savePageDefinitions(this.year, this.pageDefinition).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.delay(1000).then(() => {
          this.reloadPage(Constants.DRAFT_STATUS);
        });
      }
    });
  }

  reloadPage(expectedStatus) {
      this.service.getAllPageDefinitionsForEdit().subscribe(result => {
        localStorage.setItem(Constants.PAGE_DEFINITIONS, JSON.stringify(result));
        this.pageDefinition = CommonUtils.getPage(this.id);
        if (this.pageDefinition.status === expectedStatus || expectedStatus === undefined) {
          this.columnCount = this.pageDefinition.categories.length;
          this.descriptionHtml = this.showdownConverter.makeHtml(this.pageDefinition.description);
          if (this.pageDefinition.status === Constants.PUBLISHED_STATUS) {
            this.pageDefinition.publishedId = this.pageDefinition.id;
            this.pageDefinition.id = null;
          }
          const doc = DocumentModel.initFromPageDefinition(this.pageDefinition, 'Page Definition');
          this.docService.emit(doc);
        } else {
          this.delay(1000).then(() => this.reloadPage(expectedStatus));
        }
        this.loading = false;
        this.docService.emitReload('');
    });
  }

  queuePageDefinitions() {
    this.loading = true;
    if (this.pageDefinition.publishedId !== null) {
      this.service.queuePageDefinitions(this.pageDefinition.id).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.delay(1000).then(() => {
            this.reloadPage(Constants.QUEUED_STATUS);
          });
        }
      });
    }
  }

  revertPageDefinitions() {
    this.loading = true;
    if (this.pageDefinition.publishedId !== null) {
      this.service.revertPageDefinitions(this.pageDefinition.id).subscribe((data) => {
        if (data.message === Constants.SUCCESS) {
          this.delay(1000).then(() => {
            this.reloadPage(Constants.PUBLISHED_STATUS);
          });
        }
      });
    }
  }
}
