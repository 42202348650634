import {Injectable} from '@angular/core';
import {MzModalService} from 'ngx-materialize';
import {ConfirmOptions} from '@app/components/confirm-modal/confirm-options';
import {ConfirmModalComponent} from '@app/components/confirm-modal/confirm-modal.component';
import {ConfirmType} from '@app/utils/constants/confirm-constants';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor(private modalService: MzModalService) {

  }

  delete(id: string) {
    const options = new ConfirmOptions(ConfirmType.Appendix, id);
    this.modalService.open(ConfirmModalComponent, {confirmOptions: options});
  }
}
