import {Component, Input, OnInit} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {CommonUtils} from '@app/utils/common/common-utils';
import {MzBaseModal} from 'ngx-materialize';
import {PublishType} from '@app/utils/constants/publish-constants';
import {PublishService} from '@app/services/publish.service';
import {Constants} from '@app/utils/constants/constants';
import {EventService} from '@app/services/event.service';
import {Response} from '@app/model/response.model';
import {ConfirmOptions} from '@app/components/confirm-modal/confirm-options';
import {ConfirmType} from '@app/utils/constants/confirm-constants';
import {PageDefinitionService} from '@app/services/page-definition.service';

export enum ConfirmState {
  Start,
  InProgress,
  Complete
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent extends MzBaseModal implements OnInit {
  @Input() confirmOptions: ConfirmOptions;
  theme = CommonUtils.getCompany().toLocaleLowerCase();
  title: string;
  message: string;
  currentState: ConfirmState = ConfirmState.Start;
  ConfirmState = ConfirmState;
  publishSucceeded = false;

  constructor(private publishService: PublishService,
              private eventService: EventService<string>,
              private pageDefinitionService: PageDefinitionService) {
    super();
  }

  ngOnInit() {
    this.title = this.getTitleText();
    this.message = this.getConfirmMessageText();
  }

  delete() {
    this.currentState = ConfirmState.InProgress;

    this.deleteContent().subscribe((response) => {
      this.currentState = ConfirmState.Complete;
      this.publishSucceeded = response.message === Constants.SUCCESS;
      this.message = this.publishSucceeded
        ? 'Record deleted!'
        : 'An error occurred while deleting.  Please contact a system administrator.';
    });
  }

  complete() {
    this.eventService.emit('reload');
  }

  private getTitleText(): string {
    let titleText = '';

    switch (this.confirmOptions.confirmType) {
      case ConfirmType.Appendix:
        titleText = 'Delete Appendix';
        break;
    }

    return titleText;
  }

  private getConfirmMessageText(): string {
    let confirmText = '';

    switch (this.confirmOptions.confirmType) {
      case ConfirmType.Appendix:
        confirmText = `Are you sure you want to delete a published record? This action cannot be undone!`;
        break;
    }

    return confirmText;
  }

  private deleteContent(): Observable<Response> {
    let response: Observable<Response>;

    switch (this.confirmOptions.confirmType) {
      case ConfirmType.Appendix:
        response = this.pageDefinitionService.deleteAppendix(this.confirmOptions.id);
        break;
    }

    return response;
  }
}
