import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsService} from '@app/services/account-details.service';
import {MzBaseModal} from 'ngx-materialize';
import {CommonUtils} from '@app/utils/common/common-utils';
import {AccountDetails} from '@app/model/account.details.model';

@Component({
  selector: 'app-view-account-details',
  templateUrl: './account-details.component.html'
})
export class AccountDetailsComponent extends MzBaseModal implements OnInit {
  @Input() accountId: string;
  @Input() detailId: string;
  @Input() accountDetails: AccountDetails;
  pageName: string;
  theme = CommonUtils.getCompany().toLocaleLowerCase();

  constructor(public service: AccountDetailsService) {
    super();
  }

  ngOnInit() {
    if (!this.accountDetails) {
      this.service.getAccountDetails(this.accountId, this.detailId).subscribe((data) => {
        this.accountDetails = data;
        this.pageName = CommonUtils.getPageName(this.accountDetails.pageNo);
      });
    } else {
      this.pageName = CommonUtils.getPageName(this.accountDetails.pageNo);
    }
  }
}
