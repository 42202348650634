import { Category } from '@app/model/category.model';
import {Constants} from '@app/utils/constants/constants';

export class Page {
    constructor(
        public id: string,
        public title: string,
        public link: string,
        public categories: Array<Category>,
        public pageNumber: string,
        public description: string,
        public headline: string,
        public status: string,
        public publishedId: string,
        public editedDate?: string,
        public editedBy?: string,
        public overviewImage?: string
    ) {
    }

    static fromData(obj: any): Page {
      if (!obj) {
        return null;
      }

      const categories =
        obj.categories
          ? obj.categories.map((objCategory) => Category.fromData(objCategory))
          : new Array<Category>();

      const page =
        new Page(
          obj.id,
          obj.title,
          obj.link,
          categories,
          obj.pageNumber,
          obj.description,
          obj.headline,
          obj.status,
          obj.publishedId,
          obj.editedDate,
          obj.editedBy,
          obj.overviewImage
        );

      return page;
    }

    public hasQueuedContent(): boolean {
      if (this.status === Constants.QUEUED_STATUS) {
        return true;
      }

      if (this.categories) {
        for (const category of this.categories) {
          if (category.hasQueuedContent()) {
            return true;
          }
        }
      }

      return false;
    }
}
