import {Component, Input, OnInit} from '@angular/core';
import {MzBaseModal} from 'ngx-materialize';
import {CommonUtils} from '@app/utils/common/common-utils';
import {Constants} from '@app/utils/constants/constants';
import {EventService} from '@app/services/event.service';
import {AccountManual} from '@app/model/account.manual.model';
import {AccountManualService} from '@app/services/account-manual.service';
import {PublishType} from '@app/utils/constants/publish-constants';
import {coerceToNumber} from '@material-git/grid-list/grid-list-measure';
import _ from 'lodash';

export enum CopyState {
  Confirm,
  Copying,
  Complete
}


@Component({
  selector: 'app-copy',
  templateUrl: './copy.component.html'
})
export class CopyComponent extends MzBaseModal implements OnInit {
  @Input() year;
  accountManuals: Array<AccountManual>;
  theme = CommonUtils.getCompany().toLocaleLowerCase();
  selectedYear = CommonUtils.getYear();
  futureYear;
  loading = true;
  CopySucceeded = false;
  currentState: CopyState = CopyState.Confirm;
  CopyState = CopyState;
  message: string;


  constructor(public service: AccountManualService, private eventService: EventService<string>) {
    super();
  }

  ngOnInit() {
    this.service.getAccountManuals().subscribe((data) => {
      this.accountManuals = data;
      if (this.accountManuals !== null) {
        console.log(this.year);
      }
      this.futureYear = (coerceToNumber(_.maxBy(this.accountManuals, 'year').year) + 1).toString();
    });
  }

  copyManual() {
    this.currentState = CopyState.Copying;
    this.service.copyAccountManual(this.selectedYear, this.futureYear).subscribe((data) => {
      if (data.message === Constants.SUCCESS) {
        this.currentState = CopyState.Complete;
        this.CopySucceeded = data.message === Constants.SUCCESS;
        this.message = 'Accounting Manual Copy was successful !';
      } else if (data.message === Constants.INVALID_YEAR) {
        this.currentState = CopyState.Complete;
        this.message = 'Accounting Manual Copy was already done for this year. You cannot do another copy !';
      }
    });
  }

  complete() {
    this.eventService.emit('reload');
  }
}
