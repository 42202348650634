import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {AccountDetails} from '@app/model/account.details.model';
import {ErrorHandlingService} from '@app/services/error-handling.service';
import {Constants} from '@app/utils/constants/constants';
import {Response} from '@app/model/response.model';
import * as data from '@app/services/json/data.json';
import {Example} from "@app/model/example.model";

@Injectable({
  providedIn: 'root'
})
export class AccountDetailsService {
  loading: boolean;

  constructor(private http: HttpClient, private errorHandlingService: ErrorHandlingService) {
  }

  public getAccountDetails(accountId: string, accountDetailId: string): Observable<AccountDetails> {
    const url =
      Constants.ACCOUNT_DETAILS_URL
        .replace('{accountId}', accountId)
        .replace('{accountDetailId}', accountDetailId);

    return this.http.get<AccountDetails>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getAllAccountDetails(company: string, year: string): Observable<AccountDetails[]> {
    let url =
      Constants.ALL_ACCOUNT_DETAILS_URL
        .replace('{company}', company)
        .replace('{year}', year);

    // START THIS CODE TO BE REMOVED
   /* const result: AccountDetails[] = new Array<AccountDetails>();
    // @ts-ignore
    const dataArray = JSON.parse(JSON.stringify(data.default));
    dataArray.forEach((d) => {
      const examples: Example[] = new Array<Example>();
      d.examples.forEach((e) => {
        examples.push(new Example(e.exampleName, e.debit, e.credit));
      });
      const ad = new AccountDetails(d.id, d.detailId, d.accountName, d.accountNumber, d.pageNo, d.lineNo, d.company,
        d.category, d.description, d.comments, examples);
      result.push(ad);
    });
    return of(result);*/
    // END: THIS CODE TO BE REMOVED

    return this.http.get<AccountDetails[]>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getAccountDetailsForEdit(accountNumber: string, accountDetailId: string): Observable<AccountDetails> {
    const url =
      Constants.ACCOUNT_DETAILS_FOR_EDIT_URL
        .replace('{accountNumber}', accountNumber)
        .replace('{accountDetailId}', accountDetailId);

    return this.http.get<AccountDetails>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public getAccountsByDetailId(year: string, accountDetailId: string): Observable<Array<Account>> {
    const url =
      Constants.ACCOUNTS_BY_DETAIL_ID_URL
        .replace('{year}', year)
        .replace('{accountDetailId}', accountDetailId);

    return this.http.get<Array<Account>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public saveAccountDetails(year: string, accountDetails: AccountDetails): Observable<Response> {
    const body = {
      year,
      accountDetails
    };

    return this.http.post<Response>(Constants.SAVE_ACCOUNT_DETAILS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public queueAccountDetails(year: string, id: string, detailId: string): Observable<Response> {
    const body = {
      year,
      id,
      detailId
    };

    return this.http.post<Response>(Constants.QUEUE_ACCOUNT_DETAILS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public revertAccountDetails(year: string, id: string, detailId: string): Observable<Response> {
    const body = {
      year,
      id,
      detailId
    };

    return this.http.post<Response>(Constants.REVERT_ACCOUNT_DETAILS_URL, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
}
