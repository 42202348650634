import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from '@app/utils/routes';
import {BrowserConfig} from '@app/utils/browser-config';


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: BrowserConfig.useHash })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
