import {ComponentRef, Injectable} from '@angular/core';
import {Company} from '@app/utils/company';
import {Constants} from '@app/utils/constants/constants';
import {AuthConstants} from '@app/utils/constants/auth-constants';
import {User} from '@app/model/user.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '@env';
import {EventService} from '@app/services/event.service';
import {MzBaseModal, MzModalService} from 'ngx-materialize';
import {ConfigResult} from '@app/utils/common/configResult';
import {interval, Observable, of, timer} from 'rxjs';
import {AccessTokenResponse} from '@app/utils/common/accessTokenResponse';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})


export class AuthService {

  user: any = null;
  modal: ComponentRef<MzBaseModal>;
  authConfig: ConfigResult;
  tokenResponse: AccessTokenResponse;

  constructor(private eventService: EventService<boolean>, private modalService: MzModalService, private httpClient: HttpClient) {
    // this.refresheUser();
    this.loadStorage();
    if (this.tokenResponse !== null) {
      this.refreshToken(this.tokenResponse.refresh_token);
    }
    this.authConfig = JSON.parse(localStorage.getItem(AuthConstants.STS_SERVER));
  }

  authorizationUrl(): string {
    const url = `${environment.authorization_endpoint}?client_id=RMD-FA`
      + `&response_type=${AuthConstants.RESPONSE_TYPE}`
      + `&scope=${AuthConstants.SCOPE}`
      + `&state=${this.generateState()}&nonce=${this.generateNonce()}`
      + `&redirect_uri=${environment.redirectUrl}&${environment.queryParams}`;
    return url;
  }

  requestIdTokenFromCode(code: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: environment.authorization
      })
    };
    return this.httpClient.post(environment.access_token,
      `grant_type=${environment.grantType}&code=${code}&redirect_uri=${environment.redirectUrl}`, httpOptions);
  }

  refreshToken(code: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: environment.authorization
      })
    };
    return this.httpClient.post(environment.access_token,
      `grant_type=${environment.grantTypeRefresh}&refresh_token=${code}`, httpOptions);
  }

  generateNonce() {
    let last = null;
    let repeat = 0;

    if (typeof length === 'undefined') {
      length = 15;
    }
    const now = Math.pow(10, 2) * +new Date();

    if (now === last) {
      repeat++;
    } else {
      repeat = 0;
      last = now;
    }

    const s = (now + repeat).toString();
    return +s.substr(s.length - length);
  }

  generateState() {
    return Date.now() + '' + Math.random() + Math.random();
  }

  getCompany(): string {
    return localStorage.getItem(Constants.COMPANY);
  }

  setCompany(): void {
    if (this.user != null) {
      if (this.user.dealer_number.startsWith('6')) {
        localStorage.setItem(Constants.COMPANY, Company[Company.Lexus]);
      } else {
        localStorage.setItem(Constants.COMPANY, Company[Company.Toyota]);
      }
    }
  }

  setEditMode() {
    if (this.isUserAdmin()) {
      localStorage.setItem(Constants.EDIT_MODE_FLAG, 'false');
    }
  }

  getIdToken(): string {
    return this.tokenResponse.id_token;
  }

  setUser() {
    this.loadStorage();
  }

  getUser(): User {
    if (this.user === null) {
      return User.EMPTY;
    }
    return new User(
      this.user.UserId, this.user.first_name, this.user.last_name, this.user.email, this.user.dealer_number,
      this.user.region_code, this.user.roles.split(','),
      this.user.spin_id, this.user.tmscorporateregioncode, this.user.user_type
    );
  }

   loadStorage() {
    this.tokenResponse = JSON.parse(localStorage.getItem(AuthConstants.ACCESS_TOKEN_RESPONSE));
  }

  getIsAuthorized(): Observable<boolean> {
    this.loadStorage();
    if (this.tokenResponse === null) {
      return of(false);
    } else {
      const timeout = jwt_decode(this.tokenResponse.id_token);
      const now = Date.now();
      const msToExpire = timeout.exp * 1000 - now;
      if (msToExpire <= 0) {
        return of(false);
      } else {
        return of(true);
      }
    }
  }

  isUserAdmin() {
    return this.getUser().roles.includes(AuthConstants.RMD_ADMIN);
  }

  getEditModeFlag(): boolean {
    const editModeEnabled = localStorage.getItem(Constants.EDIT_MODE_FLAG) === 'true';
    return this.isUserAdmin() && editModeEnabled;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();

    window.location.replace(environment.logoutUrl + '?id_token_hint=' + this.tokenResponse.id_token + '&'
      + 'post_logout_redirect_uri=' + encodeURIComponent(environment.redirectUrl)
      + '&client_id=' + encodeURIComponent(environment.clientId));
  }

}

