import {Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import {CommonUtils} from '@app/utils/common/common-utils';
import {Page} from '@app/model/page.model';
import {AuthService} from '@app/services/auth.service';
import {AdminService} from '@app/services/admin.service';
import {PublishService} from '@app/services/publish.service';
import {PublishModalService} from '@app/services/publish-modal.service';
import {AccountsService} from '@app/services/accounts.service';
import {Account} from '@app/model/account.model';
import {Router} from '@angular/router';
import {Constants} from '@app/utils/constants/constants';
import {MzModalService} from 'ngx-materialize';
import {AccountDetailsComponent} from '@app/components/account-details/account-details.component';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  loading = false;
  overviewDetails: Page;
  private publishClickedSubscription;
  newAccounts: Array<Account>;
  pageAccounts: Array<{ title: string, accounts: Array<Account> }>;
  currentYear = CommonUtils.getYear();

  constructor(public authService: AuthService, public service: AccountsService,
              private publishService: PublishService,
              private publishModalService: PublishModalService,
              public adminService: AdminService,private modalService: MzModalService) {
    super();
  }

  ngOnInit() {
    this.initAdminEventHandlers();
    this.overviewDetails = CommonUtils.getPage(0);
    if (!this.authService.getEditModeFlag()) {
      if (localStorage.getItem(Constants.PAGE_NEW_ACCOUNTS)) {
        this.pageAccounts = JSON.parse(localStorage.getItem(Constants.PAGE_NEW_ACCOUNTS));
      } else {
        this.loading = true;
        this.service.getNewAccounts().subscribe((data: Array<{ title: string, accounts: Array<Account> }>) => {
          this.pageAccounts = data;
          localStorage.setItem(Constants.PAGE_NEW_ACCOUNTS, JSON.stringify(this.pageAccounts));
          this.loading = false;
        });
      }
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.publishClickedSubscription.unsubscribe();
  }

  isPublishable(): boolean {
    return this.overviewDetails && this.overviewDetails.hasQueuedContent();
  }

  private initAdminEventHandlers() {
    this.publishClickedSubscription =
      this.adminService.publishClicked.subscribe(() => {
        this.publishModalService.publishOverview(this.overviewDetails.id);
      });
  }
  
  redirectToAccountDetails(accountId, detailId, accountNumber) {
    
    this.modalService.open(AccountDetailsComponent, {accountId, detailId});
    
  }
  
}
