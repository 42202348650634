import {Injectable} from '@angular/core';
import {MzModalService} from 'ngx-materialize';
import {PublishModalComponent} from '@app/components/publish-modal/publish-modal.component';
import {PublishType} from '@app/utils/constants/publish-constants';
import {PublishOptions} from '@app/components/publish-modal/publish-options';
import {AppendixItem} from '@app/model/appendix.model';

@Injectable({
  providedIn: 'root'
})
export class PublishModalService {

  constructor(private modalService: MzModalService) {

  }

  public publishManual() {
    const options = new PublishOptions(PublishType.Manual);

    this.modalService.open(PublishModalComponent, {publishOptions: options});
  }

  public publishOverview(id: string) {
    const options = new PublishOptions(PublishType.Overview, id);

    this.modalService.open(PublishModalComponent, {publishOptions: options});
  }

  public publishPage(id: string, pageNumber: string) {
    const options = new PublishOptions(PublishType.Page, id, pageNumber);

    this.modalService.open(PublishModalComponent, {publishOptions: options});
  }

  public publishAppendix(appendices: Array<AppendixItem>) {
    const options = new PublishOptions(PublishType.Appendix, null, null, appendices);

    this.modalService.open(PublishModalComponent, {publishOptions: options});
  }
}
