export class AccountManual {
    constructor(
        public company: string,
        public year: string,
        public status: string,
        public version?: string,
        public editedDate?: string,
        public editedBy?: string
    ) {}
}
