import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ErrorHandlingService} from '@app/services/error-handling.service';
import {CommonUtils} from '@app/utils/common/common-utils';
import {Response} from '@app/model/response.model';
import {PublishConstants} from '@app/utils/constants/publish-constants';
import {Constants} from '@app/utils/constants/constants';
import {Publish} from '@app/model/publish.model';
import {Summary} from '@app/model/summary.model';
import {AppendixItem} from '@app/model/appendix.model';

@Injectable({
  providedIn: 'root'
})
export class PublishService {
  loading: boolean;

  constructor(private http: HttpClient,
              private errorHandlingService: ErrorHandlingService) {

  }

  public publishManual(fnErrorHandler): Observable<Response> {
    const body = {
      year: CommonUtils.getYear()
    };

    return this.http.post<Response>(PublishConstants.PUBLISH_MANUAL_URL, body).pipe(
      catchError(fnErrorHandler)
    );
  }

  public publishOverview(fnErrorHandler): Observable<Response> {
    const body = {
      year: CommonUtils.getYear()
    };

    return this.http.post<Response>(PublishConstants.PUBLISH_OVERVIEW_URL, body).pipe(
      catchError(fnErrorHandler)
    );
  }

  public publishPage(id: string, pageNumber: string, includeAccounts: boolean, fnErrorHandler): Observable<Response> {
    const body = {
      year: CommonUtils.getYear(),
      id,
      pageNumber,
      includeAccounts
    };
    return this.http.post<Response>(PublishConstants.PUBLISH_PAGE_URL, body).pipe(
      catchError(fnErrorHandler)
    );
  }

  publishAppendix(appendices: Array<AppendixItem>): Observable<Response> {
    const body = {
      appendices
    };

    return this.http.post<Response>(Constants.PUBLISH_APPENDIX, body).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }
  public getSummaryForPublish(year: string):
    Observable<Array<Publish>> {
    const url = Constants.GET_PUBLISH_SUMMARY
      .replace('{year}', year);

    return this.http.get<Array<Publish>>(url).pipe(
      retry(0),
      catchError(this.errorHandlingService.handleError)
    );
  }

  public revert(summary: Array<Summary>, fnErrorHandler): Observable<Response> {
    return this.http.post<Response>(Constants.REVERT_ALL, summary).pipe(
      catchError(fnErrorHandler)
    );
  }
  public queue(summary: Array<Summary>, fnErrorHandler): Observable<Response> {
    return this.http.post<Response>(Constants.QUUEUE_ALL, summary).pipe(
      catchError(fnErrorHandler)
    );
  }
  public publish(summary: Array<Summary>, fnErrorHandler): Observable<Response> {
    return this.http.post<Response>(Constants.PUBLISH_ALL, summary).pipe(
      catchError(fnErrorHandler)
    );
  }
}
