import {Routes} from '@angular/router';
import {HomeComponent} from '@app/components/home/home.component';
import {OverviewComponent} from '@app/components/overview/overview.component';
import {PageDefinitionsComponent} from '@app/components/page-definitions/page-definitions.component';
import {AmSearchComponent} from '@app/components/am-search/am-search.component';
import {AuthGuardService} from '@app/services/auth-guard.service';
import {EditPageDefinitionsComponent} from '@app/components/page-definitions/edit/edit-page-definitions/edit-page-definitions.component';
import {AdminComponent} from '@app/components/admin/admin.component';
import {EditOverviewComponent} from '@app/components/overview/edit-overview.component';
import {EditAccountDetailsComponent} from '@app/components/account-details/edit/edit-account-details/edit-account-details.component';
import {IE9StateComponent} from '@app/components/ie9-state/ie9-state.component';
import {SettingsComponent} from '@app/components/home/header/settings/settings.component';
import {EditContainerComponent} from '@app/components/admin/edit-container/edit-container.component';
import {PublishContainerComponent} from '@app/components/publish/publish-container/publish-container.component';
import {PublishDataComponent} from '@app/components/publish/publish-data/publish-data.component';
import {AppendixListComponent} from '@app/components/appendix/appendix-list/appendix-list.component';
import {EditAppendixComponent} from '@app/components/appendix/edit-appendix/edit-appendix.component';
import {FileViewerComponent} from '@app/components/file-viewer/file-viewer.component';

export const routes: Routes = [
  {
    path: 'home', component: HomeComponent,
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full'
      },
      {
        path: 'overview',
        component: OverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'page/999',
        component: AppendixListComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'page/:id',
        component: PageDefinitionsComponent,
        canActivate: [AuthGuardService]
      }
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'fileViewer/:src',
    component: FileViewerComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'edit',
        component: EditContainerComponent,
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'overview',
            component: EditOverviewComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'page-definitions/:id',
            component: EditPageDefinitionsComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'account-details/:accountId/:accountNumber/:detailId',
            component: EditAccountDetailsComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'appendix/:id',
            component: EditAppendixComponent,
            canActivate: [AuthGuardService]
          }
        ]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'publish',
        component: PublishContainerComponent,
        canActivate: [AuthGuardService],
        children: [

          {
            path: '',
            redirectTo: 'all',
            pathMatch: 'full'
          },
          {
            path: 'all',
            component: PublishDataComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'draft',
            component: PublishDataComponent,
            canActivate: [AuthGuardService]
          },
          {
            path: 'queued',
            component: PublishDataComponent,
            canActivate: [AuthGuardService]
          }
        ]
      }

    ]
  },
  {
    path: 'search/:searchTerm',
    component: AmSearchComponent,
    canActivate: [AuthGuardService]
  },


  // for IE9
  // this resolves the error of "route not found for 'state'"
  {
    path: 'state',
    component: IE9StateComponent
  }
];

