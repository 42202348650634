import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponent} from '@app/components/base/base.component';
import { Location } from '@angular/common';
import { AuthService } from '@app/services/auth.service';
import { AdminService } from '@app/services/admin.service';
import {CommonUtils} from '@app/utils/common/common-utils';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent extends BaseComponent implements OnInit {
  loading = true;
  theme;
  adminService;

  constructor(private injector: Injector, private location: Location, public authService: AuthService) {
    super();
  }

  callback() {
    this.loading = false;
  }

  ngOnInit() {
    this.adminService = this.injector.get(AdminService);
    this.theme = CommonUtils.getCompany().toLocaleLowerCase();
    this.adminService.cancelClicked.subscribe(() => this.location.back());
  }

}
